import i18n from 'i18next'
import { initReactI18next } from 'react-i18next'
import detector from 'i18next-browser-languagedetector'
import de from './translations/de.json'
import en from './translations/en.json'
import es from './translations/es.json'
import fi from './translations/fi.json'
import ko from './translations/ko.json'
import pt from './translations/pt.json'
import ch from './translations/ch.json'
import ar from './translations/ar.json'
import bg from './translations/bg.json'
import tr from './translations/tr.json'
export { Language } from './translations/Language'

const resources = {
  de: { translation: de },
  en: { translation: en },
  es: { translation: es },
  fi: { translation: fi },
  ko: { translation: ko },
  pt: { translation: pt },
  ch: { translation: ch },
  ar: { translation: ar },
  bg: { translation: bg },
  tr: { translation: tr }
}

i18n
  .use(detector)
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    resources,
    interpolation: {
      escapeValue: false // react already safes from xss
    },
    partialBundledLanguages: true,
    fallbackLng: false
  })

export default i18n
