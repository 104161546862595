import React from 'react'
import { Image } from 'rebass'
import styles from './styles'
import { useMediaQuery } from 'react-responsive'

type ContentProps = {
  source: string
  type: string
}
const GalleryContent: React.FC<ContentProps> = ({ source, type }) => {
  const mediaQueryMobile = useMediaQuery({
    query: '(max-width: 768px)'
  })
  return (
    <>
      {type == 'image' ? (
        <Image
          src={source}
          alt="Test"
          sx={
            !mediaQueryMobile
              ? {
                  ...styles.imageContent
                }
              : { ...styles.mobileImageContent }
          }
        ></Image>
      ) : type == 'video' ? (
        <iframe
          src={source}
          frameBorder={0}
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowFullScreen
        ></iframe> // to be implemented for videos
      ) : (
        ''
      )}
    </>
  )
}
export default GalleryContent
