import React, { useRef, useState, useContext, useEffect } from 'react'
import { Button, Flex, Text, Box, Image } from 'rebass'
import { UneeqContext, useUneeqState } from 'uneeq-react-core'
import { Label, Input, Textarea } from '@rebass/forms'
import useOnClickOutside from '../../hooks/useOnClickOutside'
import styles from './styles'
import { FaChevronRight } from 'react-icons/fa'
import Logo from '../Logo/Logo'
import { useTranslation } from 'react-i18next'
import { keyframes } from '@emotion/core'
const slide_in = keyframes`
  from {
    right: -2500px;
  }
  to {
    right: 0;
  }
`
const slide_out = keyframes`
  from {
    transform: translateX(0px);
  }
  to {
    transform: translateX(1000px);
  }
`
const LeaveFeedback = () => {
  const { config, dispatch } = useContext(UneeqContext)
  const modalRef = useRef()
  const [name, setName] = useState('')
  const [email, setEmail] = useState('')
  const [subject, setSubject] = useState('')
  const [feedback, setFeedback] = useState('')
  const [feedbackError, setFeedbackError] = useState(false)
  const [emailError, setEmailError] = useState({ status: false, message: '' })
  const [errorMessage, setErrorMessage] = useState({
    status: false,
    message: ''
  })
  const [successMessage, setSuccessMessage] = useState({
    status: false,
    message: ''
  })
  const [openAnimation, setOpenAnimation] = useState(false)
  const [closeAnimation, setCloseAnimation] = useState(false)
  const [openModal, setOpenModal] = useState(false)
  const { menuOpen } = useUneeqState()

  const open = (modal: string) => () => {
    if (menuOpen) {
      dispatch({ type: 'closeModal' })
    } else {
      dispatch({ type: 'open' + modal, payload: true })
    }
  }
  const openMenu = open('Menu')
  const { t } = useTranslation()

  const close = () => {
    setCloseAnimation(true)
    setTimeout(() => {
      dispatch({ type: 'closeSupportFeedback' })
      setCloseAnimation(false)
      openMenu()
    }, 2000)
  }

  useOnClickOutside(modalRef, close)

  const validateEmail = email => {
    const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    return re.test(email)
  }

  const sendEmail = async obj => {
    try {
      const response = await fetch(
        'https://api.digitalhumans.roche.com?action=feedback',
        {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json'
          },
          body: JSON.stringify(obj)
        }
      )
      if (response.ok) {
        setOpenModal(true)
        setSuccessMessage({
          status: true,
          message: t('SupportForm.emailSuccess')
        })
        setTimeout(() => {
          setSuccessMessage({ status: false, message: '' })
          close()
        }, 5000)
      } else {
        setErrorMessage({
          status: true,

          message: t('SupportForm.emailFailure')
        })
        setTimeout(() => {
          setErrorMessage({ status: false, message: '' })
          close()
        }, 5000)
      }
    } catch (err) {
      console.log('Support Form error: ', err)
    }
  }

  const handleSubmit = async e => {
    e.preventDefault()

    if (feedback === '') {
      setFeedbackError(true)
      setTimeout(() => {
        setFeedbackError(false)
      }, 5000)
    }

    if (email !== '') {
      const isEmailValid = validateEmail(email)
      if (!isEmailValid) {
        setEmailError({ status: true, message: t('SupportForm.emailError') })
        setTimeout(() => {
          setEmailError({ status: false, message: '' })
        }, 5000)
      }
      if (isEmailValid && feedback !== '') {
        const obj = {
          digitalHuman: config.persona.name,
          name,
          email,
          subject,
          feedback
        }
        sendEmail(obj)
      }
    } else {
      if (feedback !== '') {
        const obj = {
          digitalHuman: config.persona.name,
          name,
          email,
          subject,
          feedback
        }
        sendEmail(obj)
      }
    }
  }
  const backgroundColor = config.persona.style.home.background.chatBackground
    ? config.persona.style.home.background.chatBackground
    : config.persona.style.home.background.mobile
  const color = config.persona.style.colors.buttons.basic

  return (
    <Flex
      sx={{
        ...styles.content,
        background: backgroundColor
      }}
    >
      {openModal && (
        <Flex sx={styles.sentModalContainer}>
          <Flex sx={styles.sentModal}>
            <Text> {t('SupportForm.emailSuccess')}</Text>
            <Button
              onClick={() => {
                setOpenModal(false)
                close()
              }}
              sx={styles.sentButton}
            >
              {t('SupportForm.backToSettings')}
            </Button>
          </Flex>
        </Flex>
      )}

      <Logo
        svg={config?.persona.style?.logo?.svg}
        url={config?.persona.style?.logo?.url}
        link={config?.persona.style?.logo?.link}
        handleClick={() => {
          config?.persona.style?.logo?.link &&
            window.open(config?.persona.style?.logo?.link, '_blank')
        }}
      />
      <Flex sx={styles.closeIcon} onClick={close}>
        <FaChevronRight size={24} color="#fff" style={{ cursor: 'pointer' }} />
      </Flex>

      <Flex
        sx={
          closeAnimation
            ? {
                ...styles.main,
                animation: `${slide_out} 4s`
              }
            : openAnimation
            ? {
                ...styles.main,
                animation: `${slide_in} 1.5s`
              }
            : {
                ...styles.main
              }
        }
      >
        <Flex sx={styles.emptyColumn}></Flex>
        <Flex sx={styles.inputs}>
          <Box sx={styles.inputContainer}>
            <Label sx={styles.label} htmlFor="name">
              {t('SupportForm.name')}
            </Label>
            <Input
              onChange={e => setName(e.target.value)}
              value={name}
              id="name"
              name="name"
              placeholder={t('SupportForm.namePlaceholder')}
              sx={styles.input}
            />
          </Box>
          <Box sx={styles.inputContainer}>
            <Label sx={styles.label} htmlFor="name">
              {t('SupportForm.email')}
            </Label>
            <Input
              onChange={e => setEmail(e.target.value)}
              value={email}
              id="name"
              name="name"
              placeholder={t('SupportForm.emailPlaceholder')}
              sx={styles.input}
              required
            />
            {emailError.status && (
              <Text sx={styles.errorMessage}>{emailError.message}</Text>
            )}
          </Box>
          <Box sx={styles.inputContainer}>
            <Label sx={styles.label} htmlFor="subject">
              {t('SupportForm.subject')}
            </Label>
            <Input
              onChange={e => setSubject(e.target.value)}
              value={subject}
              id="subject"
              name="subject"
              placeholder={t('SupportForm.subjectPlaceholder')}
              sx={styles.input}
            />
          </Box>
          <Box sx={styles.inputContainer}>
            <Label sx={styles.label} htmlFor="comment">
              {t('SupportForm.message')}
            </Label>
            <Textarea
              onChange={e => setFeedback(e.target.value)}
              value={feedback}
              sx={styles.textArea}
              id="comment"
              name="comment"
              required
            />
            {feedbackError && (
              <Text sx={styles.errorMessage}>
                {t('SupportForm.messageError')}
              </Text>
            )}
          </Box>

          {errorMessage.status && (
            <Text
              sx={{
                ...styles.errorMessage,
                textAlign: 'center',
                marginTop: '3rem'
              }}
            >
              {errorMessage.message}
            </Text>
          )}
          <Box sx={styles.inputContainer}>
            <Box sx={styles.buttonContainer}>
              <Button
                my={4}
                sx={{ ...styles.sendButton, ...color }}
                onClick={handleSubmit}
                type="button"
              >
                {t('SupportForm.sendButton')}
              </Button>
            </Box>
          </Box>
        </Flex>
      </Flex>
    </Flex>
  )
}
export default LeaveFeedback
