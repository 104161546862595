import {
  DigitalHuman,
  PasscodeOverlay,
  LoadingTip,
  testState
} from 'uneeq-react-ui'
import React, { useState, useRef } from 'react'
import { Box } from 'rebass'
import Esmo from '../app/assets/img/esmo.png'
import { ThemeProvider } from 'emotion-theming'
import theme from '../theme'
import Config from '../config'
import Home from './Home'
import assets from './assets'
import SessionTimedOut from './SessionTimedOut'
import { useTranslation } from 'react-i18next'

const App = (props: any) => {
  const config = Config.getInstance()

  const backgroundStyle = {
    label: 'wrapper',
    width: '100%',
    minHeight: '100%',
    backgroundSize: 'cover',
    backgroundPosition: config.persona.name === 'AnaEsmo' ? 'center' : 'right',
    backgroundColor: '#000000',

    backgroundImage: config.persona.features.newPolicy
      ? config.persona.style.home.background.chatBackground.includes('http')
        ? [
            `url('${config.persona.style.home.background.chatBackground}')`,
            `url('${config.persona.style.home.background.chatBackground}')`,
            `url('${config.persona.style.home.background.chatBackground}')`,
            `url('${config.persona.style.home.background.desktop}')`,
            `url('${config.persona.style.home.background.desktop}')`,
            `url('${config.persona.style.home.background.desktop}')`
          ]
        : [
            config.persona.style.home.background.chatBackground,
            config.persona.style.home.background.chatBackground,
            config.persona.style.home.background.chatBackground,
            `url('${config.persona.style.home.background.desktop}')`,
            `url('${config.persona.style.home.background.desktop}')`,
            `url('${config.persona.style.home.background.desktop}')`
          ]
      : [
          `url('${config.persona.style.home.background.mobile}')`,
          `url('${config.persona.style.home.background.mobile}')`,
          `url('${config.persona.style.home.background.mobile}')`,
          `url('${config.persona.style.home.background.desktop}')`,
          `url('${config.persona.style.home.background.desktop}')`,
          `url('${config.persona.style.home.background.desktop}')`
        ],
    overflow: 'hidden',
    position: 'absolute'
  }

  const { t, i18n } = useTranslation()
  console.log('in18', i18n.language)

  let loadingTips: LoadingTip[] = t('loading.tips', { returnObjects: true })
  let avatarName
  if (config?.name === 'Diana' && i18n.language === 'fi') {
    avatarName = 'Elina'
  } else {
    avatarName = config?.name
  }
  props.document.title = avatarName + ' - ' + t('document.title')

  // For faster testing, skip straight to digitalHuman
  const [step, goTo] = useState(testState ? 'digitalHuman' : 'welcome')

  const tokenRef = useRef<string>()
  const start = () => {
    goTo('digitalHuman')
  }

  const restart = () => goTo('welcome')

  return (
    <ThemeProvider theme={theme}>
      <Box
        sx={{
          ...backgroundStyle,
          overflow: step === 'welcome' ? 'visible' : 'hidden'
        }}
      >
        {step === 'digitalHuman' ? (
          <DigitalHuman
            assets={assets}
            config={config}
            token={tokenRef.current}
            loadingTips={loadingTips}
            onTimedOut={() => goTo('timed-out')}
            restart={restart}
            onSessionEnded={() => goTo('restart')}
          />
        ) : step === 'timed-out' ? (
          <SessionTimedOut restart={restart} />
        ) : (
          <Home startSession={start} />
        )}
      </Box>
    </ThemeProvider>
  )
}

export default App
