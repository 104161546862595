import { keyframes } from '@emotion/core'

const heartFade = keyframes`
    0% {
        opacity: 1;
        transform: translateY(0.063rem) scale(1);
        -webkit-transform: translateY(0.063rem) scale(1);
    }
    100% {
        opacity: 0;
        transform: translateY(-3.125rem) scale(2);
        -webkit-transform: translateY(-3.125rem) scale(2);
    }
`

export const styles = {
  conatianer: {
    label: 'onscreen-info',
    position: 'absolute',
    right: [20, 20, 20, 20, 40, 40],
    maxWidth: ['90%', '90%', '90%', '95%', 560, 560],
    top: [20, 20, 20, 20, 88, 88],
    bottom: [20, 20, 20, 20, 195, 195],
    width: '100%',
    display: ['none', 'none', 'none', 'none', 'flex', 'flex'],
    flexDirection: 'column',
    justifyContent: 'center',
    left: [20, 20, 20, 20, 'auto', 'auto'],
    zIndex: [4, 4, 4, 4, 1, 1]
  },
  conatianerMobile: {
    label: 'onscreen-info',
    position: 'absolute',
    right: 0,
    left: 0,
    top: 0,
    bottom: 0,
    width: '100%',
    height: '100%',
    display: ['none', 'none', 'none', 'none', 'flex', 'flex'],
    flexDirection: 'column',
    justifyContent: 'center',
    zIndex: [4, 4, 4, 4, 1, 1],
    backdropFilter: 'blur(1.188rem)'
  },
  card: {
    label: 'info-card',
    color: 'text',
    display: 'flex',
    flexDirection: 'column',
    minHeight: 250
  },
  action: {},
  information: {
    content: {
      zIndex: 90,
      label: 'information-content',
      position: 'relative',
      minHeight: 0,
      li: {
        fontSize: 1
      },
      '& a:visited': {
        color: 'secondary'
      },

      // Needed to make sure safari keeps 'information-scroll' from overflowing this
      display: 'flex',
      flexDirection: 'column'
    },
    scroll: {
      label: 'information-scroll',
      overflow: 'auto',
      maxHeight: '100%',
      py: '0.938rem'
    },
    closeButtonContainer: {
      label: 'close-button-container',
      display: ['flex', 'flex', 'flex', 'flex', 'none', 'none'],
      justifyContent: 'flex-end'
    },
    closeButton: {
      label: 'close-button',
      p: 0,
      mt: [0, 0, 0, 0, '-0.625rem', '-0.625rem'],
      mr: [0, 0, 0, 0, '-0.625rem', '-0.625rem'],
      zIndex: 2
    },
    topFade: {
      label: 'top-fade',
      height: 25,
      left: 0,
      right: 0,
      position: 'absolute',
      top: 0,
      zIndex: 1,
      background:
        'linear-gradient(to bottom, rgba(255, 255, 255, 1) 50%,rgba(255, 255, 255, 0) 100%);'
    },
    bottomFade: {
      label: 'bottom-fade',
      bottom: 0,
      height: 25,
      left: 0,
      right: 0,
      position: 'absolute',
      background:
        'linear-gradient(to top, rgba(255, 255, 255, 1) 50%,rgba(255, 255, 255, 0) 100%);'
    },
    buttonsContainer: {
      label: 'buttons-container',
      mt: [0, 0, '0.625rem', '0.625rem', 11, 11],
      justifyContent: 'flex-end',
      '& svg': {
        zIndex: 1
      }
    },
    expandButton: {
      label: 'expand-button',
      alignItems: 'center',
      display: ['none', 'none', 'none', 'none', 'flex', 'flex'],
      '& svg': {
        mr: '0.313rem',
        mt: ['0.188rem', '0.188rem', '0.188rem', '0.188rem', 0, 0]
      }
    },
    saveButton: {
      label: 'save-button',
      alignItems: 'center',
      display: 'flex',
      '& svg': {
        mr: '0.125rem'
      }
    },
    buttonText: {
      label: 'buttonText',
      fontSize: 1,
      display: ['none', 'none', 'none', 'block']
    }
  },
  savedItems: {
    label: 'saved-items',
    heartContainer: {
      label: 'saved-items-heart-container',
      animation: `${heartFade} 1.5s ease forwards`,
      zIndex: 1,
      position: 'absolute'
    },
    savedItemsButton: {
      label: 'saved-items-button',
      display: ['none', 'none', 'none', 'none', 'flex', 'flex'],
      cursor: 'pointer',
      zIndex: 4,
      '& button': {
        alignItems: 'center',
        display: 'flex',
        color: 'primary',
        fontSize: 2
      },
      '& button svg': {
        mr: 1
      }
    },
    mobileSavedItemsButton: {
      label: 'mobile-saved-items-button',
      display: ['flex', 'flex', 'flex', 'flex', 'none', 'none'],
      cursor: 'pointer',
      zIndex: 4,
      color: 'primary',
      width: [30, 30, 40, 40, 40, 40],
      height: [30, 30, 40, 40, 40, 40],
      p: 0,
      borderRadius: 1000,
      '& svg': {
        mr: 0,
        minWidth: 15
      }
    }
  },
  informationExpanded: {
    container: {
      label: 'info-expanded-container',
      position: 'fixed',
      top: 0,
      left: 0,
      width: '100%',
      height: '100%',
      zIndex: 3,
      backdropFilter: 'blur(0.25rem)',
      webkitBackdropFilter: 'blur(0.25rem)',
      backgroundColor: 'rgba(0,0,0,0.7)',
      alignItems: 'center',
      justifyContent: 'center',
      overflow: 'hidden',
      'h1,h2,h3,h4': {
        fontSize: '1.125rem'
      }
    },
    closeButtonContainer: {
      label: 'close-button-container',
      justifyContent: 'flex-end'
    },
    closeButton: {
      label: 'close-button',
      p: 0,
      mt: [0, 0, 0, 0, '-0.625rem', '-0.625rem'],
      mr: [0, 0, 0, 0, '-0.625rem', '-0.625rem']
    },
    contentAndButton: {
      label: 'content-and-button',
      width: ['95%', '95%', '95%', '95%', 850, 850],
      maxHeight: ['95%', '95%', '95%', '95%', 552, 552],
      p: 12,
      flexDirection: 'column'
    },
    scrollContainer: {
      label: 'scroll-container',
      fontSize: '1.125rem',
      whiteSpace: 'pre-wrap',
      overflow: 'auto',
      '&::-webkit-scrollbar': {
        display: 'none'
      },
      '& a:visited': {
        color: 'secondary'
      },
      msOverflowStyle: 'none',
      scrollbarWidth: 'none',
      maxHeight: [350, 350, 350, 350, 550, 550]
    },
    saveButton: {
      label: 'save-button',
      width: 82,
      alignItems: 'center',
      display: 'flex',
      '& svg': {
        mr: '0.125rem'
      }
    },
    trashButton: {
      label: 'trash-button',
      alignItems: 'center',
      display: 'flex',
      '& svg': {
        mr: '0.313rem'
      }
    },
    minimizeButton: {
      label: 'minimize-button',
      color: 'secondary',
      alignItems: 'center',
      display: ['none', 'none', 'none', 'none', 'flex', 'flex'],
      '& svg': {
        mr: '0.313rem'
      }
    },
    downloadButton: {
      label: 'download-button',
      alignItems: 'center',
      display: 'flex',
      '& svg': {
        mr: '0.313rem'
      }
    },
    buttonsContainer: {
      label: 'buttons-container',
      mt: [5, 5, 5, 5, 11, 11],
      mb: [5, 5, 5, 5, 0, 0],
      justifyContent: 'flex-end',
      '& button': {
        pb: [6, 6, 6, 6, 2, 2]
      },
      '& svg': {
        zIndex: 1
      }
    },
    paginationContainer: {
      label: 'pagination-container',
      borderTop: '0.063rem solid transparent',
      borderTopColor: 'grey',
      pt: 7,
      px: 14,
      justifyContent: 'space-between',
      alignItems: 'center'
    }
  },
  suggestedResponses: {
    title: {
      color: 'textAlternate',
      label: 'title',
      mb: 6,
      fontWeight: 'bold'
    },
    button: {
      label: 'suggestedResponses-button',
      fontSize: '0.938rem',
      padding: '1.875rem',
      letterSpacing: '0.2px',
      lineHeight: '1.5rem',
      fontWeight: 100,
      fontFamily: 'RocheSans-Light',
      transition: 'none !important'
    },
    scrollArea: {
      flex: 1,
      overflow: 'auto',
      justifyContent: 'flex-end',
      border: '0px',
      marginBottom: '0.625rem'
    },

    itemsContainer: {
      label: 'suggestedResponses-itemsContainer',
      width: '100%',
      maxHeight: ['70vh', '70vh', '70vh', '100%'],
      flexDirection: ['row', 'column', 'column', 'column', 'column', 'column'],
      overflowX: ['scroll', 'auto', 'auto', 'auto', 'auto', 'auto'],
      overflowY: 'scroll',
      maxWidth: ['30%', '100%', '100%', '100%', '100%', '100%'],
      paddingTop: '1.875rem',
      paddingBottom: '1.875rem',
      alignItems: [
        'flex-end',
        'flex-end',
        'flex-end',
        'flex-end',
        'flex-end',
        'flex-end'
      ],
      justifyContent: [
        'flex-start',
        'flex-start',
        'center',
        'center',
        'center',
        'center'
      ],
      '@media(orientation: landscape) and (max-width: 825px)': {
        overflow: 'auto',
        maxHeight: '100%'
      },
      '@media(max-width: 500px)': {
        flexDirection: 'column',
        overflowX: 'scroll',
        maxWidth: '100%'
      },
      '&::-webkit-scrollbar': {
        width: '0.125rem',
        height: '0.313rem',
        opacity: 0.8
      },
      '&::-webkit-scrollbar-track': {
        width: '0.25rem',
        pt: '1rem'
      },
      '&::-webkit-scrollbar-thumb': {
        background: '#CCCCCC',
        borderRadius: '1.5rem'
      }
    },
    item: {
      width: 'fit-content',
      marginTop: '0.625rem',
      label: 'suggestedResponses-item',
      zIndex: 30,
      transition: 'none !important',
      alignItems: 'center',
      justifyContent: 'center',
      cursor: 'pointer',
      position: 'relative',
      mx: ['0.125rem', '0.125rem', '0.125rem', '0.125rem', 3, 3],
      textAlign: 'right',
      display: 'inline-flex',
      minWidth: '6rem',
      whiteSpace: 'normal',
      wordWrap: 'break-word',
      borderRadius: 30,
      color: '#FFFFFF',
      // fontFamily: 'Berthold Imago',
      fontFamily: 'RocheSans-Light',
      fontSize: '1.2rem',
      '@media only screen and (max-width: 1400px)': {
        maxWidth: '80%',
        textAlign: 'right'
      },
      '@media only screen and (min-width: 700px) and (max-width: 1100px)': {
        maxWidth: '50%'
      },
      '@media only screen and (max-width: 700px)': {
        maxWidth: '90%'
      },
      '@media(orientation: landscape) and (max-width: 825px)': {
        padding: '0.188rem'
      },
      //Galaxy Fold
      '@media(orientation: landscape) and (min-width: 650px) and (max-width: 655px)': {
        py: '0.125rem',
        px: '1.875rem'
      }
    },
    itemChat: {
      width: 'fit-content',
      marginTop: '0.625rem',
      label: 'suggestedResponses-item',
      zIndex: 30,
      transition: 'none !important',
      alignItems: 'center',
      justifyContent: 'center',
      cursor: 'pointer',
      position: 'relative',
      mx: ['0.125rem', '0.125rem', '0.125rem', '0.125rem', 3, 3],
      textAlign: 'right',
      display: 'inline-flex',
      minWidth: '6rem',
      whiteSpace: 'normal',
      wordWrap: 'break-word',
      borderBottomLeftRadius: 20,
      color: '#FFFFFF',
      fontFamily: 'RocheSans-Light',
      fontSize: '1.2rem',
      '@media only screen and (max-width: 1400px)': {
        maxWidth: '80%',
        textAlign: 'right'
      },
      '@media only screen and (min-width: 700px) and (max-width: 1100px)': {
        maxWidth: '50%'
      },
      '@media only screen and (max-width: 700px)': {
        maxWidth: '90%'
      },
      '@media(orientation: landscape) and (max-width: 825px)': {
        padding: '0.188rem'
      },
      //Galaxy Fold
      '@media(orientation: landscape) and (min-width: 650px) and (max-width: 655px)': {
        py: '0.125rem',
        px: '1.875rem'
      }
    },
    itemDiana: {
      width: 'fit-content',
      marginTop: '0.625rem',
      label: 'suggestedResponses-item',
      zIndex: 30,
      transition: 'none !important',
      alignItems: 'center',
      justifyContent: 'center',
      // justifyContent: 'center',
      cursor: 'pointer',
      position: 'relative',
      mx: ['0.125rem', '0.125rem', '0.125rem', '0.125rem', 3, 3],
      textAlign: 'center',
      display: 'inline-flex',
      minWidth: '6.25rem',
      whiteSpace: 'normal',
      wordWrap: 'break-word',
      borderRadius: '0.6rem',
      color: '#FFFFFF',
      // fontFamily: 'Berthold Imago',
      fontFamily: 'RocheSans-Light',
      fontSize: '1.2rem',
      '@media (max-width: 1400px)': {
        width: '80%',
        textAlign: 'right'
      },
      '@media (max-width: 1000px)': {
        fontSize: '1rem'
      },
      '@media(orientation: landscape) and (max-width: 825px)': {
        padding: '0.188rem'
      },
      //Galaxy Fold
      '@media(orientation: landscape) and (min-width: 650px) and (max-width: 655px)': {
        py: '0.125rem',
        px: '1.875rem'
      }
    }
  }
}
export default styles
