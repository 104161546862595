import React, { useContext } from 'react'
import { useUneeqState, UneeqContext } from 'uneeq-react-core'
import InformationContent from './InformationContent'
import { InformationItem } from './InformationTypes'
import Transliteration from './Transliteration'
import Config from 'digital-human/src/config'

interface InformationProps {
  information: InformationItem[]
}
const Information: React.FC<InformationProps> = ({ information }) => {
  const config = Config.getInstance()
  const { onScreenInfo } = useUneeqState()
  const { state } = useContext(UneeqContext)

  const fullAvatarMode =
    onScreenInfo.information?.length < 1 ||
    onScreenInfo.information?.hasOwnProperty('event')
  return (
    <div style={{ padding: '1.25rem' }}>
      {config.persona.name === 'Diana' && !fullAvatarMode && (
        <Transliteration />
      )}
      {!state.avatarSpeaking && <InformationContent information={information} />}
    </div>
  )
}

export default Information
