import React, { useContext, useRef } from 'react'
import { Box, Button, Flex, Text } from 'rebass'
import { trackHandler, UneeqContext, useUneeqState } from 'uneeq-react-core'
import { ReactComponent as ChatIcon } from '../../assets/img/conversation2.svg'
import { ReactComponent as Timeline } from '../../assets/img/timeline.svg'
import { ReactComponent as CloseIcon } from '../../assets/img/close.svg'
import AnimatedFeedback from '../AnimatedFeedback/AnimatedFeedback'
import styles from './styles'
import useOnClickOutside from '../../hooks/useOnClickOutside'
import { useTranslation } from 'react-i18next'
import Config from 'digital-human/src/config'
import { useMediaQuery } from 'react-responsive'
import Menu from '../Menu'

interface ModalityToggleProps {
  isDisabled: boolean
  isChatEnabled?: boolean
  speechMode: boolean
  modalityToggleMode: () => void
}
export const ModalityToggle: React.FC<ModalityToggleProps> = ({
  speechMode,
  modalityToggleMode
}) => {
  const { t } = useTranslation()
  const config = Config.getInstance()
  const mediaQueryMobile = useMediaQuery({
    query: '(max-width:768px)'
  })
  const iconChatStyle = speechMode
    ? config.persona.style.colors.icons.chat.basic
    : config.persona.style.colors.icons.chat.active
  const chatColor = config.persona.style.colors.icons.chat.mobile
    ? config.persona.style.colors.icons.chat.mobile
    : iconChatStyle

  return (
    <Flex
      sx={
        mediaQueryMobile
          ? {
              ...styles.topBarButton,
              ...styles.topBarButtonChat,
              ...chatColor,
              boxShadow: 'none !important'
            }
          : {
              ...styles.topBarButton,
              ...styles.topBarButtonChat,
              ...iconChatStyle,
              boxShadow: 'none !important'
            }
      }
      onClick={modalityToggleMode}
    >
      <ChatIcon />
    </Flex>
  )
}
interface RestartProps {
  restart: () => void
}
const TopBar: React.FC<RestartProps> = ({ restart }) => {
  let { inputMode, transcriptOpen, menuOpen, onScreenInfo } = useUneeqState()
  const { state } = useContext(UneeqContext)

  const isDisabled = () =>
    state.recording || state.avatarSpeaking || state.sending

  const { dispatch, config } = useContext(UneeqContext)
  const close = () => {
    dispatch({ type: 'setInputMode', payload: 'speech' })
    dispatch({ type: 'closeTranscript' })
  }
  const mediaQueryMobile = useMediaQuery({
    query: '(max-width:768px)'
  })
  const mediaQueryExtraSmall = useMediaQuery({
    query: '(max-width:330px)'
  })
  const speechMode = inputMode === 'speech'
  const isChatEnabled = !speechMode && mediaQueryMobile

  const fullAvatarMode =
    onScreenInfo.information?.length < 1 ||
    onScreenInfo.information?.hasOwnProperty('event')

  const toggleModalityMode = () => {
    if (speechMode) {
      dispatch({ type: 'setInputMode', payload: 'text' })
      openTranscript()
    } else {
      dispatch({ type: 'setInputMode', payload: 'speech' })
      closeTranscript()
    }
  }

  const openTranscript = () =>
    dispatch({ type: 'openTranscript', payload: true })
  const closeTranscript = () => dispatch({ type: 'closeTranscript' })
  const inputModeContainer = useRef()
  useOnClickOutside(inputModeContainer, toggleModalityMode)

  return (
    <Flex sx={styles.bar}>
      {config.persona.features?.buttonOnly ? (
        <Flex sx={styles.topBarFlexContainer}>
          {!transcriptOpen ? (
            <Box
              sx={{
                ...styles.topBarMobileButtonContainer
              }}
              onClick={() => {
                if (speechMode) {
                  dispatch({ type: 'setInputMode', payload: 'text' })
                  openTranscript()
                } else {
                  dispatch({ type: 'setInputMode', payload: 'speech' })
                  closeTranscript()
                }
              }}
            >
              <Timeline width="2rem" height="2rem" />
            </Box>
          ) : (
            <Box sx={styles.avatarIcon} onClick={close}></Box>
          )}
          <AnimatedFeedback />
          <Box
            sx={{
              ...styles.topBarMobileButtonContainer,
              ...styles.topBarSettingsContainer,
              paddingTop: '0rem'
            }}
          >
            <Menu restart={restart} />
          </Box>
        </Flex>
      ) : config.persona.name === 'Diana' ? (
        <Flex
          sx={
            !mediaQueryExtraSmall && !fullAvatarMode
              ? { ...styles.topBarFlexContainerDiana, marginLeft: '6.25rem' }
              : { ...styles.topBarFlexContainerDiana, marginLeft: '-1.25rem' }
          }
        >
          {mediaQueryMobile && <AnimatedFeedback />}
          <Box
            sx={
              mediaQueryMobile
                ? {
                    ...styles.topBarMobileButtonContainer,
                    ...styles.topBarSettingsContainer,
                    paddingBottom: '0.0.',
                    marginLeft: '6.25rem',
                    marginRight:
                      !mediaQueryExtraSmall && !fullAvatarMode
                        ? '-1.25rem'
                        : '0'
                  }
                : { display: 'block' }
            }
          >
            <Menu restart={restart} />
          </Box>
        </Flex>
      ) : (
        <Flex sx={styles.topBarFlexContainer}>
          {!transcriptOpen ? (
            <Box
              sx={{
                ...styles.topBarButtonContainer,
                cursor: 'auto'
              }}
            >
              {!isChatEnabled && (
                <ModalityToggle
                  isDisabled={isDisabled()}
                  speechMode={speechMode}
                  modalityToggleMode={trackHandler(
                    toggleModalityMode,
                    speechMode
                      ? 'enable-type-mode-btn'
                      : 'disable-type-mode-btn'
                  )}
                />
              )}
            </Box>
          ) : (
            <Box sx={styles.avatarIcon} onClick={close}></Box>
          )}
          <AnimatedFeedback />
          <Box
            sx={{
              ...styles.topBarMobileButtonContainer,
              ...styles.topBarSettingsContainer
            }}
          >
            <Menu restart={restart} />
          </Box>
        </Flex>
      )}
    </Flex>
  )
}

export default TopBar
