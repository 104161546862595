import Config from 'digital-human/src/config'
const config = Config.getInstance()

export default {
  container: {
    label: 'transcript-container',
    zIndex: 20,
    position: 'absolute',
    top: 0,
    right: [0, 0, 0, 20, 20, 20],
    height: ['100%', '100%', '100%', '100%', '100%', '100%'],
    width: ['100%', '100%', '100%', '100%', 0, 0]
  },
  button: {
    boxShadow:
      '0px 0.75rem 1rem rgba(194, 186, 181, 0.16), 0px 0.25rem 0.5rem rgba(84, 79, 79, 0.16)',
    borderRadius: '0.625rem',
    padding: '0.625rem',
    gap: '0.625rem',
    cursor: 'pointer',
    minWidth: '7.813rem'
  },
  transcript: {
    label: 'Transcript',
    position: 'absolute',
    background: 'none',
    flexDirection: 'column',
    height: [
      '70% !important',
      '70% !important',
      '70% !important',
      '85vh !important',
      '85vh !important',
      '85vh !important'
    ],
    width: [
      '100% !important',
      '100% !important',
      '100% !important',
      '300px !important',
      '300px !important',
      '600px !important'
    ]
  },
  transcriptOverlay: {
    width: ['100%', '100%', '100%', '100%', '100%', '100%'],
    height: ['100%', '100%', '100%', '100%', '100%', '100%']
    // position: 'fixed',
    // backgroundColor: 'rgba(0, 0, 0, 0.4)',
    // backdropFilter: 'blur(1.188rem)'
  },
  transcriptOverlayMobile: {
    width: ['100%', '100%', '100%', '100%', '100%', '100%'],
    height: ['100%', '100%', '100%', '100%', '100%', '100%'],
    position: 'fixed',
    backgroundColor: 'rgba(0, 0, 0, 0.4)',
    backdropFilter: 'blur(1.188rem)'
  },
  transcriptImage: {
    width: 23,
    height: 23
  },

  header: {
    justifyContent: 'space-between',
    alignItems: 'center',
    width: '100%',
    zIndex: 3,
    cursor: ['auto', 'auto', 'auto', 'auto', 'move', 'move'],
    background: 'rgba(221,221,221, 0.6)',
    display: 'none'
  },

  headerBigScreen: {
    display: ['none', 'none', 'none', 'none', 'flex', 'flex'],
    background: 'rgba(221,221,221, 0.6)'
  },

  headerSmallScreen: {
    display: ['flex', 'flex', 'flex', 'flex', 'none', 'none']
  },

  headerText: {
    textTransform: 'uppercase',
    fontWeight: 700,
    fontSize: '1.563rem'
  },

  downloadIcon: {
    cursor: 'pointer',
    width: '1.5rem',
    height: '1.5rem',
    mt: '0.625rem',
    ml: '1.25rem',
    '@media (hover: hover)': {
      '&:hover .download-tooltip': { display: 'block' }
    }
  },
  tooltip: {
    backgroundColor: 'text',
    color: 'white',
    borderRadius: '0.25rem',
    p: '0.25rem 0.5rem',
    fontSize: 2,
    display: 'none',
    zIndex: 5,
    position: 'absolute'
  },
  pinButton: {
    cursor: 'pointer',
    '@media (hover: hover)': {
      '&:hover .transcript-tooltip': { display: 'block' }
    }
  },
  pinIcon: {
    width: '1.25rem',
    ml: '0.375rem',
    mr: '0.938rem',
    '& svg': {
      minWidth: '1.5rem'
    }
  },
  blur: {
    width: '100%',
    height: '6.25rem',
    backdropFilter: 'blur(0.375rem) opacity(60%)',
    position: 'absolute'
  },
  scrollArea: {
    flex: 1,
    overflow: 'auto',
    justifyContent: 'flex-end',
    border: '0px',
    marginBottom: '0.625rem'
  },
  scrollContent: {
    padding: '0.625rem 0px 0.625rem 0',
    minHeight: '100%',
    display: 'flex',
    maxWidth: '100%',
    width: '100%',
    overflowY: 'auto',
    border: '0',
    flexDirection: 'column',
    mt: 'auto',
    '& > :first-of-type': {
      mt: '5rem !important'
    },
    '& .avatar-message + .avatar-message, & .user-message + .user-message': {
      mt: 1
    }
  },
  mobileScrollContent: {
    minHeight: '100%',
    display: 'flex',
    maxWidth: '100%',
    width: '100%',
    overflowY: 'auto',
    border: '0',
    flexDirection: 'column',
    mt: 'auto',
    '& > :first-of-type': {
      mt: ['5vh', '5vh', '']
    },
    '& .avatar-message + .avatar-message, & .user-message + .user-message': {
      mt: 1
    }
  },
  mobileTranscript: {
    label: 'Transcript',
    position: 'absolute',
    background: 'none',
    flexDirection: 'column',
    mt: ['5.625rem', '5.625rem', '5.625rem'],
    height: [
      '70% !important',
      '70% !important',
      '85% !important',
      '85vh !important',
      '85vh !important',
      '85vh !important'
    ],
    width: [
      '100% !important',
      '100% !important',
      '100% !important',
      '300px !important',
      '300px !important',
      '600px !important'
    ]
  },

  message: {
    mt: 3,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    borderRadius: 'card',
    padding: 6,
    minWidth: '12.5rem',
    maxWidth: '75%',
    ml: 'auto',
    mr: 0,
    gap: '0.625rem',
    fontSize: '1rem',
    lineHeight: '1.75rem',
    boxShadow:
      '0px 0.25rem 0.25rem rgba(194, 186, 181, 0.24), 0px 0.063rem 0.125rem rgba(84, 79, 79, 0.24)'
  },
  avatarMessage: {
    background: '#FAF8F6',
    borderRadius: '0px 0px 1.25rem 0px',
    mr: 'auto',
    ml: '1.125rem',
    backgroundColor: 'white'
  },
  userMessage: {
    mr: '1.125rem',
    background: '#71C8DC',
    borderRadius: '0px 0px 0px 1.25rem'
  },
  informationMessage: {
    background: 'none !important',
    backgroundColor: 'none',
    boxShadow: '0',
    alignItems: 'flex-end',
    maxWidth: '100%',
    marginLeft: 'auto',
    marginRight: '0px'
  },
  imageMessage: {
    background: 'none !important',
    backgroundColor: 'none',
    '& *': {
      marginLeft: '0px !important',
      maxHeight: '450px'
    },
    boxShadow: '0',
    maxWidth: '75%',
    marginLeft: '0px !important',
    marginRight: 'auto',
    left: '0px'
  },

  link: { color: '#333' },
  footer: {
    zIndex: 20,
    justifyContent: 'space-between',
    alignItems: 'center',
    width: ['90%', '90%', '90%', '100%', '100%', '100%'],
    height: 45,
    position: ['static', 'static', 'static', 'static', 'static'],
    minHeight: '2.813rem',
    backgroundColor: 'white',
    marginBottom: ['0.875rem', '0.875rem', '0.875rem', '0%', '0%', '0%'],
    marginLeft: ['5%', '5%', '5%', '0%', '0%', '0%'],
    borderRadius: '0.625rem'
  },
  questionInputLabel: {
    position: 'absolute',
    left: '-10000px',
    top: 'auto',
    width: '0.063rem',
    height: '0.063rem',
    overflow: 'hidden'
  },
  textInput: {
    fontSize: [1, 0],
    border: 'none',
    borderRadius: [30, 0],
    width: '86%',
    height: 48,
    ml: [3, 0],
    radius: '0.313rem',
    '&::placeholder': { opacity: '0.6', fontSize: '0.7rem' },
    zIndex: 1,
    '&:focus': {
      outline: 'none'
    }
  },
  sendButton: {
    cursor: 'pointer',
    width: 31,
    height: 31,
    mr: [3, 0]
  },
  avatarIcon: {
    display: 'flex',
    color: '#fff',
    width: 110,
    height: 110,
    zIndex: 10000,
    cursor: 'pointer'
  },
  avatarImage: {
    background: config.persona.style.colors.bubble.avatar,
    borderRadius: '50%',
    // border: "0.063rem solid black",
    boxShadow: '0.125rem 0.125rem 0.625rem 0px rgba(179, 192, 213, 1)',
    zIndex: 10000,
    cursor: 'pointer'
  }
}
