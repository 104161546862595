const styles = {
  menuContainerPosition: {
    display: 'flex',
    top: '0.75rem !important',
    right: '0.938rem !important',
    cursor: 'pointer',
    zIndex: '90',
    FontFace: 'RocheSans-Light'
  },

  clickableBox: {
    cursor: 'pointer'
  },

  // information panel
  menuAreaContainer: {
    display: 'flex',
    borderRadius: '0.563rem',
    color: '#fff',
    width: '18.75rem',
    position: 'fixed',
    right: '3.125rem',
    top: '0px',
    padding: '0px',
    flexDirection: 'column',
    overflow: 'scroll',
    msOverflowStyle: 'none',
    scrollbarWidth: 'none',
    '&::-webkit-scrollbar': {
      display: 'none'
    },
    height: '100%',
    '@media (max-width: 700px)': {
      width: '96%',
      margin: '0 auto',
      right: 0,
      left: 0
    }
  },

  menuHeader: {
    fontSize: '2rem'
  },
  leaveMessage: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'flex-start'
  },
  leaveMessageConfirmation: {
    fontSize: '0.9rem'
  },
  restartButton: {
    fontFamily: 'RocheSans-Light',
    fontWeight: '100',
    width: '100%',
    minWidth: '6.25rem',
    fontSize: '1rem',
    boxShadow:
      '0px 0.25rem 0.25rem rgba(194, 186, 181, 0.10), 0px 0.25rem 0.25rem rgba(84, 79, 79, 0.10)',
    border: '0',
    borderRadius: '0.625rem'
  },
  supportButton: {
    fontFamily: 'RocheSans-Light',
    fontWeight: '100',
    width: '100%',
    fontSize: '1rem',
    boxShadow:
      '0px 0.25rem 0.25rem rgba(194, 186, 181, 0.10), 0px 0.25rem 0.25rem rgba(84, 79, 79, 0.10)',
    border: '0',
    borderRadius: '0.625rem'
  },
  menuItem: {
    marginTop: '2.5rem',
    display: 'flex',
    flexDirection: 'column'
  },

  menuItemLabel: {
    fontStyle: 'normal',
    fontWeight: '400',
    fontSize: '1.1rem',
    lineHeight: '1.75rem',
    textAlign: 'left',
    marginTop: '1.25rem'
  },

  Overlay: {
    left: 0,
    zIndex: 90,
    top: 0,
    width: '100%',
    height: '100%',
    position: 'fixed',
    backgroundColor: 'rgba(256, 256, 256, 0.3)',
    backdropFilter: 'blur(1.188rem)',
    alignContent: 'center',
    justifyContent: 'center',
    alignItems: 'center'
  },
  confirmationContent: {
    color: '#ffffff',
    fontFamily: 'RocheSans',
    flexWrap: 'nowrap',
    flexDirection: 'column',
    marginLeft: '0.938rem',
    marginTop: '0.625rem',
    alignContent: 'center',
    justifyContent: 'center',
    alignItems: 'center'
  },
  confirmationButtonsContainer: {
    flexDirection: 'row',
    marginTop: '0.625rem'
  },
  confirmationButtons: {
    width: '5.938rem',
    height: '2.375rem',
    borderRadius: '0.313rem',
    marginLeft: '0.625rem'
  },
  closeRightWrapper: {
    position: 'absolute',
    top: 0,
    right: 0,
    width: '3.75rem',
    height: '3.75rem',
    border: 'none',
    borderRadius: '0px 0px 0px 2.5rem',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  },
  closeRight: {
    fontSize: '1.563rem',
    color: '#fff'
  },
  textSizeChanger: {
    margin: '1.25rem 0px',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    '@media (max-width: 360px)': {
      display: 'block',
      justifyContent: 'start'
    }
  },
  textSizeChangerControllersWrapper: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    width: '10rem',
    '@media (max-width: 360px)': {
      width: '70%',
      mb: '1rem'
    }
  },
  textSizeChangerControllers: {
    position: 'relative',
    borderRadius: '50%',
    fontSize: '2.188rem',
    width: '2.75rem',
    height: '2.75rem',
    display: 'flex',
    // paddingBottom: '0.36rem',
    justifyContent: 'center',
    alignItems: 'center'
  },
  textSizeChangerController: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  },
  captionWrapper: {
    display: 'flex',
    flexDirection: 'column'
  },
  captionActionWrapper: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-start',
    fontWeight: 400,
    lineHeight: '1.75rem'
  },
  captionIcon: {
    marginRight: '0.625rem'
  }
}

export default styles
