import Config from 'digital-human/src/config'
import React from 'react'
import { Text, Button, Box } from 'rebass'
import InformationContent from '../OnScreenInfo/InformationContent'
import styles from './styles'
import { TranscriptItem } from './Transcript'

interface TranscriptMessageProps {
  line: TranscriptItem
}
const TranscriptMessage: React.FC<TranscriptMessageProps> = ({ line }) => {
  const config = Config.getInstance()
  const messageStyle = {
    ...styles.message,
    ...(line.user && !line.information
      ? { ...styles.userMessage, ...config.persona.style.colors.bubble.user }
      : {
          ...styles.avatarMessage,
          ...config.persona.style.colors.bubble.avatar
        }),
    ...(line.information
      ? line.information[0].type == 'image'
        ? styles.imageMessage
        : styles.informationMessage
      : ''),
    ...(line.message &&
      (line.message.length < 2 || line.message.includes('⠀')) && {
        display: 'none'
      })
  }
  return (
    <Box sx={messageStyle}>
      {line.information ? (
        <InformationContent
          key={line.time.getTime()}
          information={line.information}
        />
      ) : (
        <Text>
          {line.message?.split('::').map((line: string, i: number) => {
            const linkToReplace =
              'https://www.drugoffice.gov.hk/eps/do/en/pharmaceutical_trade/adr_reporting/index.html'
            if (line.includes(linkToReplace)) {
              const splittedSentence = line.split(linkToReplace)
              const sentence = React.createElement(
                'div',
                null,
                splittedSentence[0],
                React.createElement(
                  'a',
                  { href: linkToReplace, target: '_blank' },
                  'here'
                ),
                splittedSentence[1]
              )
              return <div key={i}>{sentence}</div>
            } else {
              return <div key={i}>{line}</div>
            }
          })}
        </Text>
      )}
    </Box>
  )
}

export default TranscriptMessage
