import React, { useContext } from 'react'
import { Box, Image } from 'rebass'
import { UneeqContext, useUneeqState } from 'uneeq-react-core'
import styles from './styles'
import { ReactComponent as ChevronLeft } from '../../assets/img/chev-left.svg'
import { ReactComponent as ChevronRight } from '../../assets/img/chev-right.svg'
import { ReactComponent as Close } from '../../assets/img/close.svg'
import GalleryContent from './GalleryContent'
import { useMediaQuery } from 'react-responsive'

const InformationGallery: React.FC = () => {
  const mediaQueryMobile = useMediaQuery({
    query: '(max-width: 768px)'
  })
  const { dispatch } = useContext(UneeqContext)
  const { galleryIndex, informationGallery } = useUneeqState()
  return (
    <Box
      sx={
        !mediaQueryMobile
          ? { ...styles.galleryContainer }
          : { ...styles.mobileGalleryContainer }
      }
      onDoubleClick={() => {
        console.log('Closing gallery')
        dispatch({ type: 'closeGallery' })
      }}
    >
      <Box
        sx={{
          ...styles.closeIcon,
          position: 'absolute',
          right: '0',
          top: '0',
          color: 'white',
          '&:hover': { cursor: 'pointer' },
          '& > svg': { fill: 'white' }
        }}
        onClick={() => {
          console.log('Closing gallery')
          dispatch({ type: 'closeGallery' })
        }}
      >
        <Image
          as={Close}
          alt="Close"
          sx={{ position: 'absolute', right: '0', top: '0', zIndex: '999999' }}
        />
      </Box>
      <Box
        sx={{
          ...styles.chevron,
          '&:hover': { cursor: galleryIndex > 0 ? 'pointer' : 'auto' },
          left: '0'
        }}
        onClick={() => {
          galleryIndex > 0
            ? dispatch({ type: 'changeIndex', payload: ~~galleryIndex - 1 })
            : ''
        }}
      >
        <Image
          as={ChevronLeft}
          alt="Previous"
          sx={{ color: galleryIndex > 0 ? 'white' : 'black', left: '0' }}
        />
      </Box>
      {informationGallery &&
        informationGallery.length > 0 &&
        informationGallery[galleryIndex] && (
          <GalleryContent
            source={informationGallery[galleryIndex].information[0]['source']}
            type={informationGallery[galleryIndex].information[0]['type']}
          ></GalleryContent>
        )}
      <Box
        sx={{
          ...styles.chevron,
          '&:hover': {
            cursor:
              galleryIndex + 1 < informationGallery.length ? 'pointer' : 'auto'
          },
          '& > svg': {
            right: '0'
          },
          color:
            galleryIndex + 1 < informationGallery.length ? 'white' : 'black',
          right: '0'
        }}
        onClick={() => {
          galleryIndex + 1 < informationGallery.length
            ? dispatch({ type: 'changeIndex', payload: ~~galleryIndex + 1 })
            : ''
        }}
      >
        <Image
          as={ChevronRight}
          alt="Next"
          sx={{
            color:
              galleryIndex + 1 < informationGallery.length ? 'white' : 'black',
            position: 'absolute',
            right: '0'
          }}
        />
      </Box>
    </Box>
  )
}

export default InformationGallery
