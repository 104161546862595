import React from 'react'
import { Flex } from 'rebass'
import styles from './styles'

interface LogoProps {
  svg?: string
  url?: string
  link?: string
  handleClick?: React.MouseEventHandler
}
const Logo: React.FC<LogoProps> = ({ ...props }) => {
  return (
    <>
      <Flex
        sx={{
          ...styles.container,
          ':hover': {
            boxShadow: 'none',
            cursor: props.link ? 'pointer' : 'default'
          }
        }}
        onClick={props.handleClick}
      >
        {props.svg && (
          <div dangerouslySetInnerHTML={{ __html: props.svg || '' }} />
        )}
        {props.url && <img src={props.url} />}
      </Flex>
    </>
  )
}

export default Logo
