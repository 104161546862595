import { ReactComponent as BackIcon } from '../../assets/img/left-arrow.svg'
import { UneeqContext } from 'uneeq-react-core'
import React, { useContext } from 'react'
import { Button, Flex } from 'rebass'
import styles from './styles'
import Config from 'digital-human/src/config'
import { useMediaQuery } from 'react-responsive'
import { BsArrowLeft } from 'react-icons/bs'

export interface Active {
  isEnabled: boolean
  onClick: () => void
  fullAvatarMode: boolean
}

const BackButton: React.FC<Active> = ({
  isEnabled,
  onClick,
  fullAvatarMode
}) => {
  const { state } = useContext(UneeqContext)
  const blocked = state.recording || state.avatarSpeaking || state.sending
  const config = Config.getInstance()
  const mobileMediaQuery = useMediaQuery({
    query: '(max-width: 768px)'
  })
  const mediumMediumQuery = useMediaQuery({
    query: '(max-width: 1200px)'
  })
  const desktopMediumQuery = useMediaQuery({
    query: '(min-width: 1200px)'
  })
  const svgSize = '3rem'
  const svgColor = !config.persona.features?.changeArrowColor
    ? 'white'
    : mobileMediaQuery && fullAvatarMode
    ? '#01426A'
    : 'white'
  return (
    <Flex>
      <Button
        sx={{
          ...styles.backButton,
          ...(blocked ? { opacity: 0.5 } : { opacity: 1 }),
          ':hover': {
            boxShadow: 'none',
            cursor: isEnabled ? 'pointer' : 'default'
          },
          '& > svg': {
            fill: svgColor,
            display: isEnabled ? 'auto' : 'none'
          }
        }}
        onClick={
          blocked
            ? () => {
                console.info('Button Blocked')
              }
            : onClick
        }
      >
        <BsArrowLeft size={svgSize} color={svgColor} />
      </Button>
    </Flex>
  )
}
export default BackButton
