import React, { useContext, useRef, useState } from 'react'
import { Flex, Box, Text, Button } from 'rebass'
import { UneeqContext, useUneeqState } from 'uneeq-react-core'
import useOnClickOutside from '../../hooks/useOnClickOutside'
import styles from './styles'
import { useTranslation } from 'react-i18next'
import { ReactComponent as GearIcon } from '../../assets/img/gear.svg'
import { AnimatePresence, motion } from 'framer-motion'
import { useMediaQuery } from 'react-responsive'
import { FaChevronRight } from 'react-icons/fa'
import { keyframes } from '@emotion/core'
import TextSizeChanger from './TextSizeChanger'
import CaptionController from './CaptionController'
import { BiExit } from 'react-icons/bi'

const slide_in = keyframes`
  from {
    right: -500px;
  }
  to {
    right: 3.125rem;
  }
`
const slide_out = keyframes`
  from {
    right: 3.125rem;
  }
  to {
    right: -500px;
  }
`

interface MenuProps {
  restart: () => void
}

const Menu: React.FC<MenuProps> = ({ restart }) => {
  const { dispatch, config } = useContext(UneeqContext)
  const { menuOpen, sessionID } = useUneeqState()
  const [openAnimation, setOpenAnimation] = useState(false)
  const [closeAnimation, setCloseAnimation] = useState(false)
  const revokeConsent = () => {
    const options = {
      method: 'PATCH',
      headers: { 'Content-Type': 'application/json' }
    }

    // `http://localhost:8080

    // The sessionId parameter is needed to call this fetch, I need to pass it as parameter to this Component or use context or state so the parameter is accessible here.
    fetch(
      `https://live-monitoring-app-dot-ecp-0156.ew.r.appspot.com/api/logSession/c235e7b2e50e29d549561231ecbbe28ec3fa6b61/${sessionID}/${new Date().getTime()}`,
      options
    ).catch(error => {
      console.log(`SESSSION ID='${sessionID}' NOT REVOKED.`)
      console.error(error.message)
      return
    })

    console.log(`SESSSION ID='${sessionID}' REVOKED.`)
  }

  const menuArea = useRef()
  const { t, i18n } = useTranslation()

  const open = (modal: string) => () => {
    if (menuOpen) {
      setCloseAnimation(true)
      setTimeout(() => {
        dispatch({ type: 'closeModal' })
        setCloseAnimation(false)
      }, 1000)
    } else {
      dispatch({ type: 'open' + modal, payload: true })
      setOpenAnimation(true)
      setTimeout(() => {
        setOpenAnimation(false)
      }, 2000)
    }
  }

  const colorIcon = menuOpen
    ? config.persona.style.colors.buttons.active.color
    : config.persona.style.colors.buttons.basic.color

  const menuButtonColor = config.persona.style.colors.buttons.settings
    ? config.persona.style.colors.buttons.settings
    : config.persona.style.colors.buttons.basic
  const openMenu = open('Menu')

  useOnClickOutside(menuArea, () => {
    if (menuOpen) {
      dispatch({ type: 'closeModal' })
    }
  })
  const mobileMediaQuery = useMediaQuery({
    query: '(max-width: 768px)'
  })

  const openSupportFeedback = () => {
    dispatch({ type: 'openSupportFeedback' })
  }

  const [restartConfirmation, setRestartConfirmation] = useState(false)
  return (
    <>
      <Flex sx={styles.menuContainerPosition}>
        <Box
          onClick={openMenu}
          sx={{
            zIndex: '99',
            '& > svg': {
              fill: 'white !important'
            }
          }}
        >
          {menuOpen ? (
            <Flex
              sx={
                config.persona.features.SettingsPanelWithoutBackground
                  ? { ...styles.closeRightWrapper }
                  : {
                      ...config.persona.style.colors.buttons.basic,
                      ...styles.closeRightWrapper
                    }
              }
            >
              <FaChevronRight
                style={
                  config.persona.features.SettingsPanelWithoutBackground
                    ? {
                        ...styles.closeRight,
                        ...config.persona.style.colors.icons.settings.basic
                      }
                    : {
                        ...styles.closeRight,
                        ...config.persona.style.colors.icons.settings.active
                      }
                }
              />
            </Flex>
          ) : !mobileMediaQuery ? (
            <Flex
              sx={
                config.persona.features.SettingsPanelWithoutBackground
                  ? { ...styles.closeRightWrapper }
                  : {
                      ...config.persona.style.colors.buttons.basic,
                      ...styles.closeRightWrapper
                    }
              }
            >
              <GearIcon
                style={
                  config.persona.features.SettingsPanelWithoutBackground
                    ? {
                        ...styles.closeRight,
                        ...config.persona.style.colors.icons.settings.basic
                      }
                    : {
                        ...styles.closeRight,
                        ...config.persona.style.colors.icons.settings.active
                      }
                }
              />
            </Flex>
          ) : (
            <GearIcon
              style={{
                fontSize: '1.5rem',
                display: 'block',
                position: !mobileMediaQuery ? 'absolute' : 'relative',
                top: !mobileMediaQuery && 10,
                right: !mobileMediaQuery && 10,
                ...config.persona.style.colors.icons.settings.basic
              }}
            />
          )}
        </Box>

        {menuOpen && (
          <AnimatePresence>
            <motion.div
              transition={{
                duration: 0.4
              }}
              initial={{ opacity: 0 }}
              animate={menuOpen ? { opacity: 1 } : { opacity: 0 }}
            >
              <Flex sx={styles.Overlay}>
                <Flex
                  sx={
                    closeAnimation
                      ? {
                          ...styles.menuAreaContainer,
                          color: config.persona.style.colors.primary.color,
                          animation: `${slide_out} 1.5s`
                        }
                      : openAnimation
                      ? {
                          ...styles.menuAreaContainer,
                          color: config.persona.style.colors.primary.color,
                          animation: `${slide_in} 1.5s`
                        }
                      : {
                          ...styles.menuAreaContainer,
                          color: config.persona.style.colors.primary.color
                        }
                  }
                >
                  <Flex sx={{ ...styles.menuHeader }}>
                    <Text>{t('Menu.settings')}</Text>
                  </Flex>

                  {config.persona.features.textController && (
                    <Flex sx={{ ...styles.menuItem, marginTop: '1.25rem' }}>
                      <Text sx={styles.menuItemLabel}>
                        {t('Menu.textSize')}
                      </Text>
                      <TextSizeChanger />
                    </Flex>
                  )}

                  <Flex sx={{ ...styles.menuItem, marginTop: '1.25rem' }}>
                    <Text sx={styles.menuItemLabel}>{t('Menu.subtitle')}</Text>
                    <CaptionController />
                  </Flex>

                  {/* Checking feedback start*/}
                  {config.persona.features.supportForm && menuOpen && (
                    <Flex
                      sx={{
                        ...styles.leaveMessage,
                        cursor: 'pointer'
                      }}
                    >
                      <Text sx={styles.menuItemLabel}>{t('Menu.support')}</Text>
                      <Button
                        sx={{
                          ...styles.supportButton,
                          ...menuButtonColor
                        }}
                        onClick={openSupportFeedback}
                      >
                        {t('Menu.supportMessage')}
                      </Button>
                    </Flex>
                  )}
                  {/* Checking feedback end*/}

                  <Flex sx={styles.menuItem}>
                    <Text sx={styles.leaveMessageConfirmation}>
                      {t('Menu.exitMessage')}
                    </Text>
                    <Flex
                      sx={{
                        width: '100%',
                        justifyContent: 'flex-end',
                        marginTop: '0.625rem'
                      }}
                    >
                      <Button
                        sx={{
                          ...styles.restartButton,
                          ...menuButtonColor
                        }}
                        onClick={() => {
                          setRestartConfirmation(true)
                        }}
                      >
                        <Flex
                          sx={{
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center'
                          }}
                        >
                          <Text sx={{ marginRight: '0.7rem', mt: '0.438rem' }}>
                            <BiExit style={{ fontSize: '1.37rem' }} />
                          </Text>
                          <Text>{t('Menu.exitButton')}</Text>
                        </Flex>
                      </Button>
                    </Flex>
                    <Flex
                      sx={{
                        width: '100%',
                        justifyContent: 'flex-end',
                        marginTop: '0.625rem',
                        marginBottom: '1rem'
                      }}
                    >
                      <Button
                        sx={{
                          ...styles.restartButton,
                          ...menuButtonColor
                        }}
                        onClick={() => {
                          restart()
                          revokeConsent()
                        }}
                      >
                        <Flex
                          sx={{
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center'
                          }}
                        >
                          <Text>{t('Menu.withdrawButton')}</Text>
                        </Flex>
                      </Button>
                    </Flex>
                  </Flex>
                </Flex>
              </Flex>
            </motion.div>
          </AnimatePresence>
        )}
      </Flex>

      {restartConfirmation && (
        <Flex sx={styles.Overlay}>
          <Flex sx={styles.confirmationContent}>
            <Text>{t('Menu.exitMessage')}</Text>
            <Flex sx={styles.confirmationButtonsContainer}>
              <Button
                sx={{
                  ...styles.confirmationButtons,
                  ...menuButtonColor
                }}
                onClick={() => restart()}
              >
                {t('Menu.exitConfirm')}
              </Button>
              <Button
                sx={{
                  ...styles.confirmationButtons,
                  ...menuButtonColor
                }}
                onClick={() => {
                  setRestartConfirmation(false)
                }}
              >
                {t('Menu.exitDeny')}
              </Button>
            </Flex>
          </Flex>
        </Flex>
      )}
    </>
  )
}

export default Menu
