import { getErrorLevel } from '../../hooks'
import stripSsml from './stripSsml'
import { UneeqState, AnyUneeqMessage, Config } from '../../uneeq'

export const uneeqMessageReducer = (
  state: UneeqState,
  message: AnyUneeqMessage,
  config: Config
) => {
  switch (message.uneeqMessageType) {
    case 'Ready':
      return { ...state, loadingPercentage: 25 }
    case 'AvatarQuestionText':
      state.question = message.question
      state.sending = true

      // dimsiss suggestions as soon as the user replies or
      // chooses a question
      state.onScreenInfo.suggestedResponses = undefined
      state.noInput = false
      // if there was a message input is not a problem
      state.spacebarTapped = false

      return state
    case 'AvatarQuestionEvent':
      state.question = message.question
      state.sending = true

      // dimsiss suggestions as soon as the user replies or
      // chooses a question
      state.onScreenInfo.suggestedResponses = undefined
      state.noInput = false
      // if there was a message input is not a problem
      state.spacebarTapped = false

      return state
    case 'AvatarAvailable':
      return {
        ...state,
        unavailable: false,
        loadingPercentage: 68,
        messageType: message.uneeqMessageType
      }
    case 'DeviceListUpdated':
      const devices = message.devices
      const selectedDevices = { ...state.selectedDevices }

      // set each device type to default if none is selected
      for (const deviceType in devices) {
        if (
          selectedDevices[deviceType] === undefined &&
          devices[deviceType].length > 0
        ) {
          selectedDevices[deviceType] = devices[deviceType][0].deviceId
        }
      }

      return {
        ...state,
        devices,
        selectedDevices,
        loadingPercentage: 55,
        messageType: message.uneeqMessageType
      }
    case 'SetMicSuccess':
      return {
        ...state,
        selectedDevices: {
          ...state.selectedDevices,
          audioInput: message.deviceId
        },
        loadingPercentage: 33,
        messageType: message.uneeqMessageType
      }
    case 'SetCameraSuccess':
      return {
        ...state,
        selectedDevices: {
          ...state.selectedDevices,
          videoInput: message.deviceId,
          messageType: message.uneeqMessageType
        }
      }
    case 'SetSpeakerSuccess':
      return {
        ...state,
        selectedDevices: {
          ...state.selectedDevices,
          audioOutput: message.deviceId
        },
        loadingPercentage: 73,
        messageType: message.uneeqMessageType
      }
    case 'SessionEnded':
      return {
        ...state,
        ready: false,
        sessionEnded: true,
        messageType: message.uneeqMessageType
      }
    case 'DevicePermissionAllowed':
      return {
        ...state,
        permissionAllowed: true,
        loadingPercentage: 29,
        messageType: message.uneeqMessageType
      }
    case 'serverResponse':
      const dateUser = new Date()
      const dateAvatar = new Date()

      dateUser.setSeconds(dateUser.getSeconds() - 1)

      // Add user message in the transcript array
      if (message.userMessage)
        state.transcript.push({
          message: message.userMessage.trim()
            ? message.userMessage
            : 'Text not recognized',
          user: true,
          time: dateUser
        })

      //Add bot message to the transcript ignoring events
      if (
        message.botMessage !== undefined &&
        message.botMessage &&
        message.botMessage.answer &&
        stripSsml(message.botMessage.answer)
      )
        state.transcript.push({
          message: stripSsml(message.botMessage.answer),
          user: false,
          time: dateAvatar
        })

      return {
        ...state,
        sending: false
      }
    case 'AvatarAnswer':
      return {
        ...state,
        avatarSpeaking: true,
        messageType: message.uneeqMessageType
      }
    case 'RecordingStarted':
      return {
        ...state,
        recording: true,
        recordStart: Date.now(),
        avatarSpeaking: false,
        messageType: message.uneeqMessageType
      }
    case 'RecordingStopped':
      const recordTime = Date.now() - (state?.recordStart || 0)
      const spacebarTapped = recordTime <= config.tapThreshold
      return {
        ...state,
        recording: false,
        sending: true,
        spacebarTapped,
        messageType: message.uneeqMessageType
      }
    case 'AvatarTextInputFinished':
      state.sending = false
      return {
        ...state,
        question: '',
        messageType: message.uneeqMessageType
      }
    // ************************
    case 'StartedSpeaking':
      console.log('startedspeaking case', state)
      return {
        ...state,
        // move nextSuggestedResponses into suggestedResponses
        onScreenInfo: {
          ...state.onScreenInfo,
          suggestedResponses:
            state.onScreenInfo.nextSuggestedResponses ||
            state.onScreenInfo.suggestedResponses,
          nextSuggestedResponses: undefined
        },
        avatarSpeaking: true,
        messageType: message.uneeqMessageType
      }
    case 'FinishedSpeaking':
      return {
        ...state,
        // move nextSuggestedResponses into suggestedResponses
        onScreenInfo: {
          ...state.onScreenInfo,
          suggestedResponses:
            state.onScreenInfo.nextSuggestedResponses ||
            state.onScreenInfo.suggestedResponses,
          nextSuggestedResponses: undefined
        },
        avatarSpeaking: false,
        messageType: message.uneeqMessageType
        // question: ''
      }
    case 'ClientMediaStreamUpdate':
      return {
        ...state,
        loadingPercentage:
          state.loadingPercentage < 85 ? 85 : state.loadingPercentage,
        messageType: message.uneeqMessageType
      }
    case 'SessionLive':
      return {
        ...state,
        ready: true,
        loadingPercentage: 100,
        messageType: message.uneeqMessageType
      }

    /**
     * Errors
     */
    case 'SessionError':
      if (
        message.error.match(
          /^Conversation API Error: Conversation service took longer/
        )
      ) {
        return state
      }
      try {
        const parsedError = JSON.parse(message.error)
        const errorLevel = getErrorLevel(config, parsedError.errorCode)
        if (errorLevel === 'ignore') {
          return { ...state }
        }
        return { ...state, error: parsedError }
      } catch {
        return {
          ...state,
          error: {
            errorCode: 'SessionError',
            message: message.error
          }
        }
      }

    case 'ErrorEndingSession':
      return {
        ...state,
        error: {
          errorCode: 'ErrorEndingSession',
          message: 'There was an error ending your session'
        }
      }
    case 'ServiceUnavailable':
      return {
        ...state,
        error: {
          errorCode: 'ServiceUnavailable',
          message: message.error.body.message
        }
      }
    case 'MicActivityError':
      return {
        ...state,
        error: {
          errorCode: 'MicActivityError',
          message: 'Microphone Error'
        }
      }
    case 'DeviceNotFoundError':
      // Your previous device selection is remembered but if that device
      // is not available oninit there will be an error message.
      // The error is not a problem in this case so we ignore it.
      if (!state.ready) return state

      return {
        ...state,
        error: { errorCode: 'DeviceNotFoundError', message: message.msg }
      }
    case 'AvatarUnavailable':
      return {
        ...state,
        unavailable: true
      }
    case 'ConnectionLost':
      return {
        ...state,
        error: { errorCode: 'ConnectionLost', message: 'Lost Connection' }
      }
    case 'Warning':
      return {
        ...state,
        error: { errorCode: 'warning', message: message.msg }
      }
    case 'DeviceError':
      if (message.error.name === 'NotAllowedError') {
        return { ...state, permissionAllowed: false }
      } else {
        return {
          ...state,
          error: { errorCode: 'DeviceError', message: message.error }
        }
      }
    default:
      break
  }
  return state
}
export default uneeqMessageReducer
