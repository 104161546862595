import { config } from 'dotenv'
import fetch from 'sync-fetch'

import {
  UneeqCoreConfig,
  UneeqCoreNLP,
  UneeqCorePersonaProfile
} from 'uneeq-react-core'

class Config implements UneeqCoreConfig {
  id: number = 0
  name: string = ''
  customData: object = {}
  debug: boolean = false
  languages: string[] = []
  emptyTranscriptThreshold: number = 3
  errorLevels: object = {}
  informationInTranscript: boolean = true
  nlp: UneeqCoreNLP = {
    dialogflow: { agent: '', environments: [] },
    statworks: ''
  }
  persona: UneeqCorePersonaProfile = {
    name: '',
    sendLocalAudio: true,
    uneeq: { server: '', id: '' },
    features: {
      buttonOnly: false
    },
    style: {
      caption: { toggle: false },
      home: { background: { mobile: '', desktop: '' } },
      bottom: { icons: [] },
      colors: {
        primary: {},
        icons: {
          chat: { active: {}, basic: {} },
          mic: { basic: {}, active: {} },
          settings: { basic: {}, active: {} }
        },
        bubble: { avatar: {}, user: {} },
        buttons: {
          basic: {},
          clicked: {},
          active: {},
          no_button: {},
          little_button: {},
          significant_button: {}
        }
      },
      checkIn: false
    }
  }
  playWelcome: boolean = true
  sendLocalAudio: boolean = true
  sendLocalVideo: boolean = false
  server: string = ''
  tapThreshold: number = 700
  timeout: number = 480 * 1000
  timeoutWarning: number = 180 * 1000
  usePasscode: boolean = false
  personaProfileName: string = ''

  private constructor() {
    this.loadConfigs()
    this.loadProfiles()
  }

  private static instance: any = null
  public static getInstance() {
    if (!Config.instance) Config.instance = new Config()
    return Config.instance
  }

  loadConfigs() {
    const configFile = fetch('./config.json', {
      headers: {
        Accept: 'application/vnd.citationstyles.csl+json'
      }
    }).json()

    this.id = configFile?.id
    this.name = configFile?.name
    this.server = configFile?.server
    this.persona.style.bottom.icons = configFile?.icons
    this.languages = configFile?.languages
    this.personaProfileName = configFile?.personaProfileName
    this.nlp.dialogflow = configFile?.dialogflow
    this.nlp.statworks = configFile?.statworks
    this.persona.style.checkIn = configFile?.features?.checkIn
  }

  async loadProfiles() {
    const profiles = fetch('./config-profiles.json', {
      headers: {
        Accept: 'application/vnd.citationstyles.csl+json'
      }
    }).json()

    this.persona = profiles.find(p => p.name == this.personaProfileName) || null

    if (this.persona.sendLocalAudio != undefined)
      this.sendLocalAudio = this.persona.sendLocalAudio

    if (!this.persona)
      throw new Error('Persona not found in the config-profiles.json')
  }
}

export default Config
