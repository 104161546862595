import React, { useContext, useEffect, useRef } from 'react'
import { Box, Flex, Text, Image } from 'rebass'
import { UneeqContext, useTranscript, useUneeqState } from 'uneeq-react-core'
import styles from './styles'
import TranscriptFooter from './TranscriptFooter'
import TranscriptMessage from './TranscriptMessage'
import { AnimatePresence, motion } from 'framer-motion'
import { useTranslation } from 'react-i18next'
import { InformationItem } from '../OnScreenInfo/InformationTypes'
import { useMediaQuery } from 'react-responsive'
const MotionBox: any = motion.custom(Box)

export interface TranscriptItem {
  information?: InformationItem[]
  message?: string
  user?: boolean
  time: Date
}

const Transcript: React.FC = () => {
  const { config } = useContext(UneeqContext)
  const { dispatch } = useContext(UneeqContext)
  const { transcriptOpen, inputMode, onScreenInfo } = useUneeqState()
  const { downloadTranscript, transcript } = useTranscript()
  const hasText = useRef<boolean>()
  const { t } = useTranslation()

  // autoscroll to bottom
  const scrollArea = useRef<HTMLElement>()
  useEffect(() => {
    if (scrollArea && scrollArea.current) {
      scrollArea.current.scrollTop =
        scrollArea?.current.scrollHeight - scrollArea?.current.clientHeight
    }
  }, [transcriptOpen, transcript])

  const close = () => {
    dispatch({ type: 'setInputMode', payload: 'speech' })
    dispatch({ type: 'closeTranscript' })
  }
  const mediaQueryMobile = useMediaQuery({
    query: '(max-width: 768px)'
  })
  const width = window.screen.availWidth
  const speechMode = inputMode === 'speech'
  const isChatEnabled = !speechMode && mediaQueryMobile

  return (
    <AnimatePresence>
      {transcriptOpen &&
        (!mediaQueryMobile ? (
          <MotionBox
            initial={{
              opacity: 1,
              transform: `translate(${width * 1.5}px,0px)`
            }}
            animate={{
              opacity: 1,
              transform: `translate(0px, 0px)`
            }}
            exit={{
              opacity: 1,
              transform: `translate(${width * 1.5}px,0px)`
            }}
            sx={styles.container}
          >
            {/* <TranscriptTopBar /> */}
            <Flex
              sx={
                isChatEnabled
                  ? styles.transcriptOverlayMobile
                  : styles.transcriptOverlay
              }
            ></Flex>
            <Flex variant="transcript" sx={styles.transcript}>
              <Flex ref={scrollArea} sx={styles.scrollArea} className="mask">
                <Box sx={styles.scrollContent}>
                  {transcript.length ? (
                    transcript.map((line: TranscriptItem) => {
                      return (
                        <TranscriptMessage
                          key={line.time.valueOf()}
                          line={line}
                        />
                      )
                    })
                  ) : (
                    <Text>{t('Transcript.noTranscriptAvailable')}</Text>
                  )}
                </Box>
              </Flex>
              {config.persona.features?.buttonOnly ||
              config.persona.features?.footerDisabled ? (
                ''
              ) : (
                <Flex>
                  <TranscriptFooter
                    setHasText={(has: boolean) => {
                      hasText.current = has
                    }}
                  />
                </Flex>
              )}
            </Flex>
          </MotionBox>
        ) : (
          <MotionBox
            initial={{
              opacity: 1,
              transform: `translate(${width * 1.5}px,0px)`
            }}
            animate={{
              opacity: 1,
              transform: `translate(0px, 0px)`
            }}
            exit={{
              opacity: 1,
              transform: `translate(${width * 1.5}px,0px)`
            }}
            sx={styles.container}
          >
            {/* <TranscriptTopBar /> */}
            <Flex variant="transcript" sx={styles.mobileTranscript}>
              <Flex ref={scrollArea} sx={styles.scrollArea} className="mask1">
                <Box sx={styles.mobileScrollContent}>
                  {transcript.length ? (
                    transcript.map((line: TranscriptItem) => {
                      return (
                        <TranscriptMessage
                          key={line.time.valueOf()}
                          line={line}
                        />
                      )
                    })
                  ) : (
                    <Text>{t('Transcript.noTranscriptAvailable')}</Text>
                  )}
                </Box>
              </Flex>
              {config.persona.features?.buttonOnly ||
              config.persona.features?.footerDisabled ? (
                ''
              ) : (
                <Flex>
                  <TranscriptFooter
                    setHasText={(has: boolean) => {
                      hasText.current = has
                    }}
                  />
                </Flex>
              )}
            </Flex>
          </MotionBox>
        ))}
    </AnimatePresence>
  )
}

export default Transcript
