import { useWindowWidth } from '@react-hook/window-size'
import React, {
  useCallback,
  useEffect,
  useLayoutEffect,
  useMemo,
  useRef,
  useState
} from 'react'
import { Box, Button, Flex, Text } from 'rebass'
import { trackHandler, useSupportedBrowser } from 'uneeq-react-core'
import { Language, UnsupportedBrowser } from 'uneeq-react-ui'
import { useTranslation } from 'react-i18next'
import Logo from './Logo'
import Config from '../../config'
import styles from './styles'
import './styles.css'
import ReactFlagsSelect from 'react-flags-select'
import { useMediaQuery } from 'react-responsive'
import { ReactComponent as CloseIcon } from '../assets/img/close.svg'
interface HomeProps {
  startSession: (token: string) => void
}
const Home: React.FC<HomeProps> = ({ startSession }) => {
  const config = Config.getInstance()
  const { isBrowserSupported } = useSupportedBrowser()
  const [showPasscode, setShowPasscode] = useState(false)
  const [changingLanguage, setChangingLanguage] = useState(false)
  const { t, i18n } = useTranslation()
  const [readCheck, setReadCheck] = useState(false)
  const [consentCheck, setConsentCheck] = useState(false)
  const [enabled, setEnabled] = useState(false)
  const [permissionsDenied, setPermissionsDenied] = useState(false)
  const [modalType, setmodalType] = useState('')
  const [left, setLeft] = useState(0)
  const mediaQueryMobile = useMediaQuery({
    query: '(max-width:768px)'
  })
  const [CORS, setCORS] = useState(0)
  useEffect(() => {
    // React advises to declare the async function directly inside useEffect
    async function wakingUpFunction() {
      //Wake the function up
      try {
        const response = await fetch(config.server + '?action=query', {
          method: 'POST',
          body: JSON.stringify({ warmingUp: true }),
          headers: { 'Content-Type': 'application/json' }
        })
        setCORS(response.status)
        console.info(
          `wakingUpFunction - RESPONSE: ${response.status} | CORS: ${CORS} `
        )
      } catch (e) {
        setCORS(500)
        console.info(`wakingUpFunction | CORS: ${CORS} `)
      }
    }
    CORS == 0 ? wakingUpFunction() : console.info(`CORS: ${CORS} `)
  }, [CORS])

  if (!config.languages) throw 'At least one language must be defined'

  // Selected the first language in the config
  const [selectedLanguage, setSelectedLanguage] = useState(config.languages[0])

  function displayAvatarName() {
    if (config?.name === 'Diana' && selectedLanguage === 'FI') {
      return 'Elina'
    } else {
      return config.name
    }
  }

  const languageInfos = config.languages.map(l => {
    const { label, flag } = t(`languages.${l}`, { returnObjects: true })
    return new Language(l, flag, label)
  })

  const getLabels = () => {
    var obj = {}
    for (const languageInfo of languageInfos) {
      let code = languageInfo.flagCode
      // @ts-ignore
      obj[code] = languageInfo.label
    }
    return obj
  }

  useEffect(() => {
    // @ts-ignore
    const { langCode } = languageInfos.find(
      l => l.langCode == selectedLanguage || l.flagCode == selectedLanguage
    )
    console.info(langCode)
    setChangingLanguage(true)
    i18n.changeLanguage(langCode.toLowerCase())
    setChangingLanguage(false)
  }, [selectedLanguage])
  if (showPasscode && !isBrowserSupported) {
    return <UnsupportedBrowser close={() => setShowPasscode(false)} />
  }

  if (showPasscode && !isBrowserSupported) {
    return <UnsupportedBrowser close={() => setShowPasscode(false)} />
  }

  function openModal(modalType) {
    setEnabled(enabled => !enabled)
    setmodalType(modalType)
  }
  function closeModal() {
    setEnabled(enabled => !enabled)
    setmodalType('')
  }
  interface privacyParagraph {
    title: string
    contents: Array<string>
  }
  function Modal({ enabled, modalType }): JSX.Element {
    const getModalContent = modalType => {
      if (modalType.toLowerCase() !== 'consent') {
        let titles = i18n.t(
          'PrivacyPolicy.' + modalType + 'Content.' + modalType + 'Subtitles',
          { returnObjects: true }
        )
        let subs = i18n.t(
          'PrivacyPolicy.' +
            modalType +
            'Content.' +
            modalType +
            'SubtitleContent',
          { returnObjects: true }
        )
        if (Array.isArray(titles) && Array.isArray(subs)) {
          let result: privacyParagraph[] = titles.map((title, index) => {
            return { title: titles[index], contents: subs[index] }
          })
          return result
        }
      } else {
        let contents = i18n.t(
          'PrivacyPolicy.' + modalType + 'Content.' + modalType + 'Content',
          { returnObjects: true }
        )
        if (Array.isArray(contents)) {
          let result: string[] = contents.map((content, index) => {
            return contents[index]
          })
          return result
        }
      }
    }

    const subtitles = useMemo(() => getModalContent(modalType), [modalType])

    if (enabled) {
      return (
        <Box sx={{ ...styles.modalOverlay }}>
          <Box
            sx={{
              ...styles.modalContainer,
              left: mediaQueryMobile ? '0' : left,
              mx: mediaQueryMobile ? 'auto' : '',
              mt: mediaQueryMobile ? '5vh' : '10vh',
              mb: mediaQueryMobile ? '5vh' : '',
              height: mediaQueryMobile ? '90vh' : '75vh',
              pr: '3vw',
              pl: mediaQueryMobile ? '3vw' : '',
              width: `calc(100% - ${left}px)`
            }}
          >
            <Box sx={{ ...styles.modalHeader }}>
              <Box sx={{ ...styles.closeContainer }} onClick={closeModal}>
                <CloseIcon />
              </Box>
              <Box sx={{ ...styles.modalFadeTop }} />
            </Box>
            <Box
              sx={{
                ...styles.modalContent
              }}
            >
              <Text
                sx={{
                  ...styles.modaltitleText
                }}
              >
                {' '}
                {t(
                  'PrivacyPolicy.' +
                    modalType +
                    'Content.' +
                    modalType +
                    'Title'
                )}
              </Text>
              {modalType.toLowerCase() !== 'consent' && (
                <Text sx={{ ...styles.regularText }}>
                  {t('PrivacyPolicy.' + modalType + 'Content.disclaimer')}
                </Text>
              )}
              {modalType.toLowerCase() !== 'consent' ? (
                subtitles?.map((subtitle, index) => {
                  return (
                    <Text sx={{ whiteSpace: 'pre-line' }} key={index}>
                      <Text sx={{ ...styles.modalSectionTitle }}>
                        {subtitle.title}
                        {'\n'}
                      </Text>
                      {subtitle.contents?.map((element, secondaryIndex) => {
                        return (
                          <Text
                            sx={{ ...styles.regularText, py: '1px' }}
                            key={secondaryIndex}
                          >
                            {element}
                          </Text>
                        )
                      })}
                    </Text>
                  )
                })
              ) : (
                <Text sx={{ whiteSpace: 'pre-line' }}>
                  {subtitles?.map((subtitle, index) => {
                    return (
                      <Text
                        sx={{ ...styles.regularText, py: '2px' }}
                        key={index}
                      >
                        {subtitle}
                        {'\n'}
                      </Text>
                    )
                  })}
                </Text>
              )}
            </Box>
            <Box sx={{ ...styles.modalFooter }} />
          </Box>
        </Box>
      )
    } else return <div></div>
  }
  const PrivacyPolicy = ({ setLeft }) => {
    const ref = useRef<HTMLDivElement>(null)
    useLayoutEffect(() => {
      function onResize() {
        setLeft(ref?.current?.offsetLeft)
      }
      onResize()
      window.addEventListener('resize', onResize)
      return () => window.removeEventListener('resize', onResize)
    }, [])
    return !permissionsDenied ? (
      <Box
        sx={{
          ...styles.textContainer2,
          margin: mediaQueryMobile ? 'auto' : '',
          overflowY: 'scroll'
        }}
        ref={ref}
      >
        <Text
          sx={{
            ...styles.regularText,
            ...styles.titleText
          }}
        >
          {t('Home.title')} {displayAvatarName() + ','}
        </Text>
        <Text
          sx={{
            ...styles.regularText,
            ...styles.subtitleText2
          }}
        >
          {t('Home.subtitleTop')}.
        </Text>
        <Text
          sx={{
            ...styles.regularText,
            ...styles.subtitleText2
          }}
        >
          {t('Home.subtitleBottom')}.
        </Text>
        <Text
          sx={{
            ...styles.regularText,
            ...styles.subtitleText2
          }}
        >
          {t('Home.Disclaimer.intro')}{' '}
          <u
            onClick={() => openModal('notice')}
            style={{ cursor: 'pointer', textDecorationSkipInk: 'none' }}
          >
            {t('Home.Disclaimer.privacyPolicy')}
          </u>{' '}
          {t('Home.Disclaimer.conjuction')}{' '}
          <u
            onClick={() => openModal('terms')}
            style={{ cursor: 'pointer', textDecorationSkipInk: 'none' }}
          >
            {t('Home.Disclaimer.terms')}
          </u>{' '}
          {t('Home.Disclaimer.condition')}{' '}
          <u
            onClick={() => openModal('consent')}
            style={{ cursor: 'pointer', textDecorationSkipInk: 'none' }}
          >
            {t('Home.Disclaimer.consent')}
          </u>{' '}
          {t('Home.Disclaimer.processing')}{' '}
        </Text>
        <Box
          sx={{ ...styles.mainCheckboxContainer }}
          onClick={() => handleChange('read')}
        >
          <Checkbox value={readCheck} />
          <Text
            sx={{
              ...styles.regularText,
              ...styles.checkboxText
            }}
          >
            {t('Home.consentRead')}{' '}
          </Text>
        </Box>
        <Box
          sx={{ ...styles.mainCheckboxContainer }}
          onClick={() => handleChange('consent')}
        >
          <Checkbox value={consentCheck} />
          <Text
            sx={{
              ...styles.regularText,
              ...styles.checkboxText
            }}
          >
            {t('Home.consent')}
          </Text>
        </Box>
        <Box sx={styles.buttonContainer}>
          <StartButtonModal enabled={readCheck && consentCheck} />
          <DenyButton enabled={!(readCheck && consentCheck)} />
        </Box>
      </Box>
    ) : (
      <Box
        sx={{
          ...styles.textContainer2,
          margin: mediaQueryMobile ? 'auto' : ''
        }}
        ref={ref}
      >
        <Text
          sx={{
            ...styles.regularText,
            ...styles.subtitleText2,
            flexDirection: 'column'
          }}
        >
          <Text>
            {t('Home.PermissionsRejected.consentNotice')}
            <Text
              sx={{
                ...styles.regularText,
                ...styles.subtitleText2
              }}
            >
              {t('Home.PermissionsRejected.redirectIntro')}{' '}
              <u
                style={{ cursor: 'pointer', textDecorationSkipInk: 'none' }}
                onClick={() => {
                  window.open(config.persona.features.denyRedirect)
                }}
              >
                {config.persona.features.denyRedirect}
              </u>{' '}
              {t('Home.PermissionsRejected.redirectOutro')}
            </Text>
          </Text>
        </Text>
        <Box sx={styles.buttonContainer}>
          <RestartButton />
        </Box>
      </Box>
    )
  }

  const StartButton = ({ sx }: any) => (
    <Button
      variant="outline"
      onClick={() => {
        trackHandler(startSession, 'lets-chat-btn')(true)
      }}
      sx={{
        ...styles.letsChatButton,
        ...sx,
        ...config.persona.style.colors.buttons.basic
      }}
    >
      {t('Home.start')}
    </Button>
  )
  const RestartButton = ({ sx }: any) => (
    <Button
      variant="outline"
      onClick={() => {
        setPermissionsDenied(false)
      }}
      sx={{
        ...styles.letsChatButton2,
        ...sx,
        background: config.persona.style.colors.secondary?.background,
        cursor: 'pointer'
      }}
    >
      {t('Home.back')}
    </Button>
  )

  const StartButtonModal = ({ enabled, sx }: any) => (
    <Button
      variant="outline"
      onClick={() => {
        if (enabled) trackHandler(startSession, 'lets-chat-btn')(true)
      }}
      sx={{
        ...styles.letsChatButton2,
        ...sx,
        background: enabled
          ? config.persona.style.colors.secondary?.background
          : config.persona.style.colors.secondaryAlt?.background,
        opacity: enabled ? '1' : '0.5',
        cursor: enabled ? 'pointer' : 'auto !important'
      }}
    >
      <Text
        sx={{
          ...styles.regularText,
          ...styles.buttonText,
          color: config.persona.style.colors.text
            ? config.persona.style.colors.text?.color
            : 'black'
        }}
      >
        {t('Home.start')}{' '}
      </Text>
    </Button>
  )
  const DenyButton = ({ enabled, sx }: any) => (
    <Button
      variant="outline"
      onClick={() => {
        if (enabled) {
          setPermissionsDenied(true)
          setReadCheck(false);
          setConsentCheck(false)
        }
      }}
      sx={{
        ...styles.letsChatButton2,
        ...sx,
        background: enabled
          ? config.persona.style.colors.secondary?.background
          : config.persona.style.colors.secondaryAlt?.background,
        opacity: enabled ? '1' : '0.5',
        cursor: enabled ? 'pointer' : 'auto !important'
      }}
    >
      <Text
        sx={{
          ...styles.regularText,
          ...styles.buttonText,
          color: config.persona.style.colors.text
            ? config.persona.style.colors.text?.color
            : 'black'
        }}
      >
        {t('Home.consentDeny')}{' '}
      </Text>
    </Button>
  )
  const handleChange = type => {
    if (type == 'read') setReadCheck(readCheck => !readCheck)
    else setConsentCheck(consentCheck => !consentCheck)
  }
  const Checkbox = ({ value }) => {
    return (
      <Box sx={styles.checkboxContainer}>
        <input type="checkbox" style={styles.checkbox} checked={value} />
      </Box>
    )
  }
  if (!CORS) return <></>

  return (
    <>
      {config.persona.features.newPolicy ? (
        <Flex sx={styles.mainContainer}>
          <PrivacyPolicy setLeft={setLeft} />
          <Modal enabled={enabled} modalType={modalType} />
          <Logo
            svg={config?.persona.style?.logo?.svg}
            url={config?.persona.style?.logo?.url}
            link={config?.persona.style?.logo?.link}
            handleClick={() => {
              config?.persona.style?.logo?.link &&
                window.open(config?.persona.style?.logo?.link, '_blank')
            }}
          />
          {!config.persona.features.disableLanguage && (
            <Box
              sx={{
                ...styles.languageBox
              }}
            >
              <ReactFlagsSelect
                id="language"
                className="language"
                countries={languageInfos.map(l => l.flagCode)}
                customLabels={getLabels()}
                placeholder={'selectLanguage'}
                showSelectedLabel={false}
                showOptionLabel={true}
                selectedSize={48}
                selected={
                  languageInfos.find(
                    x =>
                      x.flagCode == selectedLanguage ||
                      x.langCode == selectedLanguage
                  )?.flagCode || 'US'
                }
                onSelect={code => setSelectedLanguage(code)}
                selectButtonClassName="menu-flags-button"
              />
            </Box>
          )}
        </Flex>
      ) : (
        <>
          <Flex sx={styles.overlay}></Flex>
          <Flex sx={styles.mainContainer}>
            <Box sx={styles.textContainer}>
              <Box sx={styles.titleContainer}>
                <Text sx={styles.imSophieText}>
                  {t('Home.title')} {displayAvatarName()}
                </Text>
              </Box>
              {config.persona.name === 'AnaEsmo' ? (
                <Text sx={styles.subtitle}>
                  Your virtual assistant at the ESMO Asia Congress 2022.
                  {<br></br>}
                  {<br></br>}I am happy to provide you with basic information
                  about breast cancer.
                </Text>
              ) : (
                <Text sx={styles.subtitle}>
                  {t('Home.subtitleTop')}.{<br></br>}
                  {<br></br>}
                  {t('Home.subtitleBottom')}.
                </Text>
              )}
              <StartButton sx={styles.startButton} />
            </Box>
            <Box sx={styles.flagBox}>
              <ReactFlagsSelect
                id="language"
                className="language"
                countries={languageInfos.map(l => l.flagCode)}
                customLabels={getLabels()}
                placeholder={'selectLanguage'}
                showSelectedLabel={false}
                showOptionLabel={true}
                selectedSize={48}
                selected={
                  languageInfos.find(
                    x =>
                      x.flagCode == selectedLanguage ||
                      x.langCode == selectedLanguage
                  )?.flagCode || 'US'
                }
                onSelect={code => setSelectedLanguage(code)}
                selectButtonClassName="menu-flags-button"
              />
            </Box>
          </Flex>
        </>
      )}
    </>
  )
}

export default Home
