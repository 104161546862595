import React from 'react'
import { Flex, Text } from 'rebass'
import styles from './styles'
import Config from 'digital-human/src/config'
import {  useUneeqState } from 'uneeq-react-core'

interface UserQuestionProps {
  children: React.ReactNode
}
const UserQuestion: React.FC<UserQuestionProps> = React.forwardRef(
  ({ children }, ref): any => {
    const config = Config.getInstance()

    return (
      <Flex sx={styles.container}>
        <Flex
          sx={
            config.persona.limitTextContainerHeight
              ? { ...styles.inner, maxHeight: '18.75rem'}
              : { ...styles.inner, maxHeight: 'auto' }
          }
          ref={ref}
        >
          <Text as="span">{children}</Text>
        </Flex>
      </Flex>
    )
  }
)

export default UserQuestion
