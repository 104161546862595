import {useContext, useEffect} from 'react'
import { UneeqState } from '../uneeq'
import UneeqContext from "./UneeqContext";

/**
 * Add/remove listeners to document for the spacebar key keydown/keyup
 * for discerning when to start/stop recording
 */

interface SpacebarToTalkOptions {
  start: () => void,
  stop: () => void
}

const useSpacebarToTalk = (state: UneeqState, options: SpacebarToTalkOptions) => {
  const {
    ready,
    avatarSpeaking,
    recording,
    sending
  } = state

  const blocked = recording || avatarSpeaking || sending

  useEffect(() => {
    if (ready) {
      const shouldAct = (e: KeyboardEvent) =>
        e.code === 'Space' && // Must be spacebar
        !e.repeat && // Only first event, not autorepeat
        !(e.target instanceof HTMLInputElement) // Not when typing in input box

      const keydown = (e: KeyboardEvent) => {
        if (shouldAct(e) && !blocked) {
          options.start()
        }
      }

      const keyup = (e: KeyboardEvent) => {
        if (shouldAct(e)) {
          options.stop()
        }
      }

      document.addEventListener('keydown', keydown)
      document.addEventListener('keyup', keyup)

      // return cleanup
      return () => {
        document.removeEventListener('keydown', keydown)
        document.removeEventListener('keyup', keyup)
      }
    }
  }, [ready, avatarSpeaking, sending, recording])
}

export default useSpacebarToTalk
