import uneeqCommandReducer from './uneeqCommandReducer'
import uneeqMessageReducer from './uneeqMessageReducer'
import { closeModals, closeDialogs } from './initialState'
import { Config, UneeqState } from '../../uneeq'

export const reducer = (
  currentState: UneeqState,
  action: any,
  config: Config
) => {
  let state = { ...currentState }

  // Timeout
  // Any action other than timeoutUpdate counts as activity and resets the timeout
  if (action.type !== 'timeoutUpdate') {
    state.lastActivity = Date.now()
    state.timeLeft = config.timeout
    state.timeoutOpen = false
    state.timedOut = false
  } else {
    state.timeLeft = config.timeout - (Date.now() - state.lastActivity)
    state.timeoutOpen = state.timeLeft < config.timeoutWarning
    state.timedOut = state.timeLeft <= 0
  }

  switch (action.type) {
    case 'serverResponse':
      console.group(`serverResponse - Payload`)
      console.info(action.payload)
      console.groupEnd()

      if (!action.payload.botMessage && !action.payload.userMessage)
        return state

      console.group(`serverResponse - Payload`)
      console.info(action.payload)
      console.groupEnd()

      state = uneeqMessageReducer(
        state,
        {
          uneeqMessageType: 'serverResponse',
          botMessage: action.payload.botMessage,
          userMessage: action.payload.userMessage
        },
        config
      )

      console.info('Clean information node')
      state.onScreenInfo.information = []
      //Verify if has instructions
      if (action.payload.botMessage !== undefined) {
        const answerAvatar = JSON.parse(action.payload.botMessage?.answerAvatar)
        if (answerAvatar?.instructions?.displayHtml) {
          const command = JSON.parse(
            answerAvatar?.instructions?.displayHtml?.html
          )
          state = uneeqCommandReducer(state, command, config)
        }
      }
      state.awaitingResponse = false
      return state
    case 'setSessionID':
      return { ...state, sessionID: action.payload }
    case 'uneeqMessage':
      return uneeqMessageReducer(state, action.payload, config)
    case 'approving':
      // Aproval process is starting, we can set permissionAllowed to null (unless already known)
      if (state.permissionAllowed === undefined)
        return { ...state, permissionAllowed: null }
      break
    case 'approved':
      return {
        ...state,
        selectedInputDevice: action.payload,
        permissionAllowed: true,
        loadingPercentage: 10
      }
    case 'declined':
      return { ...state, permissionAllowed: false }
    case 'spacebarHeld':
      return { ...state, spacebarTapped: action.payload <= config.tapThreshold }
    case 'captionVisibility': {
      return { ...state, captionVisible: action.payload }
    }
    case 'changeRemSize': {
      return { ...state, remSize: action.payload }
    }
    case 'changeScreenZoom': {
      return { ...state, screenZoom: action.payload }
    }
    case 'suggestedResponseSent':
      const newState = { ...state }
      if (newState.onScreenInfo.suggestedResponses)
        newState.onScreenInfo.suggestedResponses.chosenResponse = action.payload
      newState.onScreenInfo.information = undefined
      newState.onScreenInfo.checkList = undefined
      return newState
    case 'closeModal':
      return { ...state, ...closeModals }
    case 'closeDialog':
      return { ...state, ...closeDialogs }
    case 'openMenu':
      return { ...state, ...closeModals, menuOpen: action.payload }
    case 'openFeedback':
      return { ...state, ...closeModals, feedbackOpen: action.payload }
    case 'openSettings':
      return { ...state, ...closeModals, settingsOpen: action.payload }
    case 'openSupportFeedback':
      return { ...state, ...closeModals, supportFeedbackOpen: true }
    case 'closeSupportFeedback':
      return { ...state, ...closeModals, supportFeedbackOpen: false }
    case 'openGallery':
      return {
        ...state,
        ...closeModals,
        galleryOpen: true,
        transcriptOpen: false,
        galleryIndex: action.payload
      }
    case 'closeGallery':
      return {
        ...state,
        galleryOpen: false,
        transcriptOpen: state.transcriptHasOpened
      }
    case 'changeIndex':
      return { ...state, galleryIndex: action.payload }
    case 'openTranscript':
      return {
        ...state,
        ...closeModals,
        transcriptOpen: action.payload,
        transcriptHasOpened: state.transcriptHasOpened || action.payload
      }
    case 'openPrivacy':
      return { ...state, ...closeModals, privacyOpen: action.payload }
    case 'setInputMode':
      return {
        ...state,
        inputMode: action.payload,
        typeModeFromBackend: false
      }
    case 'openEscalationForm':
      return {
        ...state,
        ...closeModals,
        escalationFormOpen: action.payload
      }

    case 'openEndConfirm':
      return {
        ...state,
        ...closeModals,
        endConfirmOpen: action.payload
      }

    case 'saveInformation':
      return {
        ...state,
        savedItems: [
          ...state.savedItems,
          state.onScreenInfo.information,
          state.onScreenInfo.checkList
        ]
      }
    case 'giveFeedback':
      return {
        ...state,
        feedbackGiven: true
      }
    case 'giveContactDetails':
      return {
        ...state,
        contactDetailsGiven: true
      }
    case 'showSavedItem':
      return {
        ...state,
        selectedSavedItem: action.payload
      }
    case 'expandInformation':
      return {
        ...state,
        expandedInfo: {
          type: 'information'
        }
      }
    case 'actionExecuted':
      console.info('Action Executed!')
      return {
        ...state,
        onScreenInfo: {
          action: undefined
        }
      }
    case 'expandSavedItem':
      return {
        ...state,
        expandedInfo: {
          type: 'savedItem'
        },
        selectedSavedItem: state.selectedSavedItem || 0
      }
    case 'collapseExpandedInfo':
      return {
        ...state,
        expandedInfo: null
      }
    case 'removeSavedItem':
      return {
        ...state,
        savedItems: state.savedItems.filter(
          (item: any, index: number) => index !== action.payload
        )
      }
    case 'closeTranscript':
      return {
        ...state,
        transcriptOpen: false,
        transcriptHasOpened: false
      }
    case 'skip':
      return {
        ...state,
        onScreenInfo: {
          ...state.onScreenInfo,
          suggestedResponses: state.onScreenInfo.nextSuggestedResponses,
          nextSuggestedResponses: undefined
        },
        question: null,
        avatarSpeaking: false,
        inputMode: 'speech'
      }
    case 'showUI':
      return {
        ...state,
        hiddenUI: false
      }
    case 'hideUI':
      return {
        ...state,
        hiddenUI: true
      }
    case 'resetTypeModeFromBackend':
      return {
        ...state,
        typeModeFromBackend: false
      }
    case 'openMobileInformation': {
      return {
        ...state,
        mobileInformationOpen: action.payload
      }
    }
    /**
     * Errors
     */
    case 'tokenError':
      return {
        ...state,
        error: {
          errorCode: 'TokenError',
          message: action.message
        }
      }
    case 'clearError':
      return { ...state, error: null, spacebarTapped: false, noInput: false }
    default:
      return state
  }
  return state
}

export default reducer
