import React, { useContext, useRef } from 'react'
import { Image, Box, Button, Flex, Text } from 'rebass'
import { Label } from '@rebass/forms'
import {
  trackHandler,
  UneeqContext,
  useUneeqSpaceToTalk,
  useUneeqState,
  useTranscript
} from 'uneeq-react-core'
import { ReactComponent as ChatIcon } from '../../assets/img/conversation.svg'
import { ReactComponent as Timeline } from '../../assets/img/timeline.svg'
import AnimatedFeedback from '../AnimatedFeedback/AnimatedFeedback'
import InputProblem from '../InputProblem'
import PushToTalk from './PushToTalk'
import styles from './styles'
import UserQuestion from './UserQuestion'
import { motion, AnimatePresence } from 'framer-motion'
import useOnClickOutside from '../../hooks/useOnClickOutside'
import { useTranslation } from 'react-i18next'
import { generateFilter } from 'colorize-filter'
import BackButton from './BackButton'
import NextButton from './NextButton'
import Config from 'digital-human/src/config'
import { useMediaQuery } from 'react-responsive'

const MotionUserQuestion = motion.custom(UserQuestion)

interface ModalityToggleProps {
  isDisabled: boolean
  isChatEnabled?: boolean
  speechMode: boolean
  modalityToggleMode: () => void
}

export const ModalityToggle: React.FC<ModalityToggleProps> = ({
  speechMode,
  modalityToggleMode
}) => {
  const { t } = useTranslation()
  const config = Config.getInstance()
  const iconChatStyle = speechMode
    ? config.persona.style.colors.icons.chat.basic
    : config.persona.style.colors.icons.chat.active
  return (
    <Button
      sx={{
        ...styles.bottomBarButton,
        ...iconChatStyle,
        pl: '0px',
        ml: '1rem',
        pr: '0px',
        width: '4rem',
        display: 'grid',
        alignItems: 'center',
        justifyContent: 'center',
        '& > svg': {
          mt: '0.625rem',
          height: '2rem',
          width: '2rem'
        },
        boxShadow: 'none !important'
      }}
      onClick={modalityToggleMode}
    >
      <ChatIcon />
    </Button>
  )
}

const BottomBar = () => {
  let { transcript } = useTranscript()

  const {
    recording,
    sending,
    startRecording,
    stopRecording
  } = useUneeqSpaceToTalk()

  let {
    inputMode,
    onScreenInfo,
    spacebarTapped,
    noInput,
    question,
    avatarSpeaking,
    answer,
    messageType,
    awaitingResponse,
    captionVisible,
    transcriptOpen,
    galleryOpen,
    menuOpen
  } = useUneeqState()

  const { state } = useContext(UneeqContext)

  const isDisabled = () =>
    state.recording || state.avatarSpeaking || state.sending

  const { dispatch, config, sendEvent } = useContext(UneeqContext)
  const send = (response: string) => {
    sendEvent(response)
  }
  const mediaQueryMobile = useMediaQuery({
    query: '(max-width: 768px)'
  })
  const speechMode = inputMode === 'speech'
  const isChatEnabled = !speechMode && mediaQueryMobile

  const toggleModalityMode = () => {
    if (speechMode) {
      dispatch({ type: 'setInputMode', payload: 'text' })
      openTranscript()
    } else {
      dispatch({ type: 'setInputMode', payload: 'speech' })
      closeTranscript()
    }
  }

  const openTranscript = () =>
    dispatch({ type: 'openTranscript', payload: true })
  const closeTranscript = () => dispatch({ type: 'closeTranscript' })
  const inputError = spacebarTapped ? 'spacebarTapped' : noInput && 'noInput'

  let shouldShowQuestion =
    question && !onScreenInfo?.suggestedResponses?.chosenResponse

  interface Response {
    label: string
    utterance: string
    event: string
  }
  let responses: Response[] =
    onScreenInfo &&
    Object.getPrototypeOf(onScreenInfo) === Object.prototype &&
    onScreenInfo.information
      ? onScreenInfo.information[0]
        ? onScreenInfo.information[0].buttons
          ? Array.from(onScreenInfo.information[0].buttons)
          : Array.from(onScreenInfo.information[0])
        : []
      : []

  let backButtonActive =
    responses && responses.length > 0
      ? responses.length > 1
        ? responses.some(response => response['label'] === 'GO_BACK')
        : responses[0]['label'] === 'GO_BACK'
      : false

  let nextButtonActive =
    responses && responses.length > 0
      ? responses.length > 1
        ? responses.some(response => response['label'] === 'GO_NEXT')
        : responses[0]['label'] === 'GO_NEXT'
      : false

  const inputModeContainer = useRef()
  useOnClickOutside(inputModeContainer, toggleModalityMode)
  const botMessages = transcript.filter(t => !t.user && t.message)
  let smallSplit =
    botMessages && botMessages[botMessages.length - 1]?.message.includes(':::')
  let splitCondition = smallSplit ? ':::' : '::'
  const fullAvatarMode =
    onScreenInfo.information?.length < 1 ||
    onScreenInfo.information?.hasOwnProperty('event')
  return (
    <Flex sx={styles.bar}>
      {config.persona.features.historyNavigation && (
        <Flex sx={styles.left}>
          <BackButton
            isEnabled={backButtonActive}
            fullAvatarMode={fullAvatarMode}
            onClick={
              backButtonActive
                ? trackHandler(() => send('GO_BACK'), 'suggested-response-btn')
                : () => {}
            }
          />
        </Flex>
      )}
      {config.persona.style.nextNavigation && (
        <Flex sx={styles.nextButtonRight}>
          <NextButton
            isEnabled={nextButtonActive}
            fullAvatarMode={fullAvatarMode}
            onClick={
              nextButtonActive
                ? trackHandler(() => send('GO_NEXT'), 'suggested-response-btn')
                : () => {}
            }
          />
        </Flex>
      )}
      {config.persona.name !== 'Diana' ? (
        <Flex
          sx={
            config.persona.limitTextContainerHeight
              ? { ...styles.userQuestionMotionContainer, overflow: 'auto' }
              : styles.userQuestionMotionContainer
          }
        >
          <AnimatePresence>
            {!galleryOpen &&
              captionVisible &&
              !transcriptOpen &&
              botMessages.length && (
                <MotionUserQuestion
                  variants={{
                    start: {
                      opacity: 0,
                      transition: {
                        delay: 1.5
                      },
                      transform: 'translateX(0.063rem)'
                    },
                    end: {
                      opacity: 1,
                      transition: {
                        delay: 1.5
                      },
                      transform: 'translateX(0.063rem)'
                    },
                    final: {
                      opacity: 0,
                      transform: 'translateX(200px)'
                    }
                  }}
                  initial="start"
                  animate="end"
                  exit="final"
                  key="question"
                >
                  <div style={{ textAlign: 'center' }}>
                    {state.avatarSpeaking &&
                      !awaitingResponse &&
                      botMessages[botMessages.length - 1].message
                        .split(splitCondition)
                        .map((line: string, i: number) => {
                          const bigText = line.length > 400
                          return (
                            <div
                              style={
                                smallSplit
                                  ? {
                                      background: 'transparent',
                                      borderBottomRightRadius: '3.125rem',
                                      color: 'white'
                                    }
                                  : bigText
                                  ? {
                                      background: 'transparent',
                                      padding:
                                        '0.125rem 1.25rem 0.125rem 0.625rem',
                                      borderBottomRightRadius: '3.125rem',
                                      color: 'white',
                                      fontSize: '1.125rem',
                                      lineHeight: '1.25rem'
                                    }
                                  : {
                                      background: 'transparent',
                                      padding:
                                        '0.625rem 1.25rem 0.625rem 0.625rem',
                                      borderBottomRightRadius: '3.125rem',
                                      color: 'white'
                                    }
                              }
                              key={i}
                            >
                              {line}
                            </div>
                          )
                        })}
                  </div>
                </MotionUserQuestion>
              )}
          </AnimatePresence>
        </Flex>
      ) : (
        config.persona.name === 'Diana' &&
        fullAvatarMode && (
          <Flex sx={styles.userQuestionMotionContainer}>
            <AnimatePresence>
              {!galleryOpen &&
                captionVisible &&
                !transcriptOpen &&
                botMessages.length && (
                  <MotionUserQuestion
                    variants={{
                      start: {
                        opacity: 0,
                        transition: {
                          delay: 1.5
                        },
                        transform: 'translateX(-200px)'
                      },
                      end: {
                        opacity: 1,
                        transition: {
                          delay: 1.5
                        },
                        transform: 'translateX(0.063rem)'
                      },
                      final: {
                        opacity: 0,
                        transform: 'translateX(200px)'
                      }
                    }}
                    initial="start"
                    animate="end"
                    exit="final"
                    key="question"
                  >
                    <div style={{ textAlign: 'center' }}>
                      {state.avatarSpeaking &&
                        botMessages[botMessages.length - 1].message
                          .split('::')
                          .map((line: string, i: number) => {
                            return (
                              <div
                                style={{
                                  background: 'transparent',
                                  padding: '0.625rem 1.25rem 0.625rem 0.625rem',
                                  borderBottomRightRadius: '3.125rem',
                                  color: 'white'
                                }}
                                key={i}
                              >
                                {line}
                              </div>
                            )
                          })}
                    </div>
                  </MotionUserQuestion>
                )}
            </AnimatePresence>
          </Flex>
        )
      )}
      <Flex
        sx={{
          ...styles.center,
          alignItems: shouldShowQuestion || avatarSpeaking ? 'center' : 'normal'
        }}
      >
        {config.icons && (
          <Flex sx={styles.iconsBox}>
            {config.persona.style.icons.map(function(icon, i) {
              return (
                <Label sx={styles.iconItem} key={i}>
                  <a href={icon.link} target="_blank">
                    <Image
                      src={icon.iconURL}
                      style={{
                        filter: generateFilter(icon.color),
                        width: '1.563rem'
                      }}
                    />
                  </a>
                </Label>
              )
            })}
          </Flex>
        )}

        <Flex sx={styles.pttOuterContainer}>
          {config.persona.features?.buttonOnly ? (
            <Flex sx={styles.pttContainer}>
              <Box
                sx={{
                  ...styles.noInputButtonContainer
                }}
              >
                {!mediaQueryMobile && <AnimatedFeedback />}
              </Box>
              <Box
                sx={{
                  ...styles.noInputButtonContainer,
                  '& > svg': {
                    '& > g': {
                      fill: !speechMode
                        ? config.persona.style.colors.buttons.active
                            .background + ' !important'
                        : 'white !important'
                    }
                  }
                }}
                onClick={() => {
                  if (speechMode) {
                    dispatch({ type: 'setInputMode', payload: 'text' })
                    openTranscript()
                  } else {
                    dispatch({ type: 'setInputMode', payload: 'speech' })
                    closeTranscript()
                  }
                }}
              >
                <Timeline />
              </Box>
            </Flex>
          ) : (
            <Flex sx={styles.pttContainer}>
              {!mediaQueryMobile && (
                <Box
                  sx={{
                    zIndex: 99,
                    ...styles.bottomBarButtonContainer,
                    '& > svg': {
                      mt: '0.313rem',
                      fill: config.persona.features?.whiteAvatar
                        ? '#01426A'
                        : '#fff',
                      '& > g': {
                        fill: config.persona.features?.whiteAvatar
                          ? '#01426A'
                          : '#fff'
                      }
                    }
                  }}
                >
                  <AnimatedFeedback />
                </Box>
              )}
              <Box
                onTouchStart={
                  isDisabled()
                    ? () => {
                        console.info('blocked - Avatart Speaking')
                      }
                    : startRecording
                }
                onTouchEnd={stopRecording}
                onMouseDown={
                  isDisabled()
                    ? () => {
                        console.info('blocked - Avatart Speaking')
                      }
                    : startRecording
                }
                onMouseUp={stopRecording}
                sx={{
                  zIndex: 99,
                  ...styles.bottomBarButtonContainer
                }}
              >
                <PushToTalk
                  recording={recording}
                  sending={sending}
                  isDisabled={false}
                />
              </Box>
              <Box sx={{ zIndex: 100, ...styles.bottomBarButtonContainer }}>
                {config.persona.name !== 'Diana' &&
                  !mediaQueryMobile &&
                  !isChatEnabled && (
                    <ModalityToggle
                      isDisabled={isDisabled()}
                      speechMode={speechMode}
                      isChatEnabled={isChatEnabled}
                      modalityToggleMode={trackHandler(
                        toggleModalityMode,
                        speechMode
                          ? 'enable-type-mode-btn'
                          : 'disable-type-mode-btn'
                      )}
                    />
                  )}
              </Box>
            </Flex>
          )}
        </Flex>
      </Flex>
      {inputError && <InputProblem error={inputError} />}
    </Flex>
  )
}

export default BottomBar
