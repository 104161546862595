const styles = {
  chevron: {
    label: 'chevron',
    position: 'absolute',
    width: '3.125rem',
    height: '3.125rem',
    marginTop: '-1.563rem',
    paddingTop: '0.938rem',
    paddingBottom: '0.938rem',
    zIndex: '999'
  },
  closeIcon: {
    label: 'close-icon',
    position: 'absolute',
    width: '3.125rem',
    height: '3.125rem'
  },
  galleryContainer: {
    label: 'gallery-container',
    '&:hover': { cursor: 'auto' },
    width: '100%',
    height: '80%',
    top: '10%',
    position: 'absolute',
    background: 'none',
    backgroundColor: 'none',
    zIndex: '21',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center'
  },
  mobileGalleryContainer: {
    label: 'mobile-gallery-container',
    '&:hover': { cursor: 'auto' },
    width: '100%',
    height: '80%',
    top: '10%',
    position: 'absolute',
    background: 'none',
    backgroundColor: 'none',
    zIndex: '21',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center'
  },
  imageContent: {
    label: 'image-content',
    width: '100%',
    height: '100%'
  },
  mobileImageContent: {
    label: 'mobile-image-content',
    width: '100%',
    height: '100%'
  }
}

export default styles
